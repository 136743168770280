import { apolloClient } from '@/main';
import { getMessage } from '@/services/shared/helper/services.js';
import { MUTATIONS } from './mutations';

import {
  getCustomerPeppol,
  getInvoiceList,
  getTenantIntegrationStorecove,
  orderItemDisputes,
  saleCancelOrder,
  saleGetCustomersOrders,
  saleGetOrderItemDetail,
  saleGetOrders,
  saleGetTotalCountOrder,
  saleUpdateOrder,
  sendInvoiceToPeppolByOrderId
} from '@/modules/sale/services/graphql';

import { repeatOrderItems } from '@/services/shared/graphql';

export const ACTIONS = {
  GET_PURCHASE: 'GET_PURCHASE',
  LOAD_MORE_PURCHASE: 'LOAD_MORE_PURCHASE',
  RELOAD_PURCHASE: 'RELOAD_PURCHASE',

  GET_PURCHASE_DETAIL: 'GET_PURCHASE_DETAIL',
  ORDER_ITEM_DISPUTES: 'ORDER_ITEM_DISPUTES',
  GET_LIST_INVOICE: 'GET_LIST_INVOICE',

  SEND_INVOICE_TO_MAIL_BY_ORDER_ID: 'SEND_INVOICE_TO_MAIL_BY_ORDER_ID',

  SEND_INVOICE_TO_PEPPOL_BY_ORDER_ID: 'SEND_INVOICE_T0_PEPPOL_BY_ORDER_ID',
  GET_CUSTOMER_PEPPOL: 'GET_CUSTOMER_PEPPOL',
  GET_TENANT_INTEGRATION_STORECOVE: 'GET_TENANT_INTEGRATION_STORECOVE',

  REPEAT_ORDER_SALE: 'REPEAT_ORDER_SALE',
  UPDATE_ORDER_SALE: 'UPDATE_ORDER_SALE',
  CANCEL_ORDER_SALE: 'CANCEL_ORDER_SALE',

  GET_VIEWS_PRICES: 'GET_VIEWS_PRICES',
  SALE_GET_CUSTOMERS_ORDERS: 'SALE_GET_CUSTOMERS_ORDERS',
  SALE_GET_TOTAL_COUNT_ORDER: 'SALE_GET_TOTAL_COUNT_ORDER'
};

export default {
  async [ACTIONS.GET_PURCHASE]({ commit, state }, { params, isLoadMore = false, onFinish }) {
    commit(MUTATIONS.GET_PURCHASE_REQUEST, isLoadMore);
    try {
      const { data } = await apolloClient.query({
        query: saleGetOrders,
        variables: { ...state.params, ...params }
      });

      // get total count
      if (state.params.isIncludeTotalCount) state.totalCount = data.saleGetOrders.totalCount;

      if (onFinish) onFinish();
      commit(MUTATIONS.GET_PURCHASE_SUCCESS, { list: data.saleGetOrders });
    } catch (error) {
      console.log(error);
      commit(MUTATIONS.GET_PURCHASE_FAILURE, error);
    }
  },

  async [ACTIONS.LOAD_MORE_PURCHASE]({ commit, dispatch }, { params, isLoadMore = true, onFinish }) {
    // update search params
    commit(MUTATIONS.UPDATE_QUERY_PARAMS, { params });
    dispatch(ACTIONS.GET_PURCHASE, { isLoadMore, onFinish });
  },

  async [ACTIONS.RELOAD_PURCHASE]({ commit, dispatch }, variables) {
    // update search params
    commit(MUTATIONS.UPDATE_QUERY_PARAMS, {
      params: {
        isIncludeTotalCount: true,
        offset: 0,
        isLoadMore: false,
        ...variables
      }
    });

    dispatch(ACTIONS.GET_PURCHASE, {});
  },

  async [ACTIONS.SALE_GET_CUSTOMERS_ORDERS]({ commit }, params) {
    try {
      const { data } = await apolloClient.query({
        query: saleGetCustomersOrders,
        variables: params
      });

      commit(MUTATIONS.SALE_GET_CUSTOMERS_ORDERS_SUCCESS, { list: data.saleGetCustomersOrders });
    } catch (error) {
      commit(MUTATIONS.SALE_GET_CUSTOMERS_ORDERS_FAILURE, error);
    }
  },
  // Get detail purchase
  async [ACTIONS.GET_PURCHASE_DETAIL]({ commit }, params) {
    commit(MUTATIONS.GET_PURCHASE_DETAIL_REQUEST);
    try {
      const { data } = await apolloClient.query({
        query: saleGetOrderItemDetail,
        variables: params
      });

      commit(MUTATIONS.GET_PURCHASE_DETAIL_SUCCESS, { list: data.saleGetOrderItemDetail });
    } catch (error) {
      commit(MUTATIONS.GET_PURCHASE_DETAIL_FAILURE, error);
    }
  },

  // order item Disputes
  async [ACTIONS.ORDER_ITEM_DISPUTES]({ commit }, params) {
    commit(MUTATIONS.ORDER_ITEM_DISPUTES_REQUEST);
    try {
      const { data } = await apolloClient.mutate({
        mutation: orderItemDisputes,
        variables: params
      });
      commit(MUTATIONS.ORDER_ITEM_DISPUTES_SUCCESS, data.orderItemDisputes);
    } catch (error) {
      commit(MUTATIONS.ORDER_ITEM_DISPUTES_FAILURE, error);
    }
  },

  // get list invoice
  async [ACTIONS.GET_LIST_INVOICE]({ commit }, params) {
    commit(MUTATIONS.GET_LIST_INVOICE_REQUEST);
    try {
      const { data } = await apolloClient.query({
        query: getInvoiceList,
        variables: params
      });

      commit(MUTATIONS.GET_LIST_INVOICE_SUCCESS, { list: data.saleGetInvoiceList });
    } catch (error) {
      commit(MUTATIONS.GET_LIST_INVOICE_FAILURE, error);
    }
  },

  // Sen invoice to pepol by order_id
  async [ACTIONS.SEND_INVOICE_TO_PEPPOL_BY_ORDER_ID]({ commit }, params) {
    commit(MUTATIONS.SEND_INVOICE_TO_PEPPOL_BY_ORDER_ID_REQUEST);
    try {
      const { data } = await apolloClient.mutate({
        mutation: sendInvoiceToPeppolByOrderId,
        variables: params
      });

      commit(MUTATIONS.SEND_INVOICE_TO_PEPPOL_BY_ORDER_ID_SUCCESS, data.sendInvoiceToPeppolByOrderId);
    } catch (error) {
      commit(MUTATIONS.SEND_INVOICE_TO_PEPPOL_BY_ORDER_ID_FAILURE, error);
    }
  },

  // get customer peppol
  async [ACTIONS.GET_CUSTOMER_PEPPOL]({ commit }, params) {
    commit(MUTATIONS.GET_CUSTOMER_PEPPOL_REQUEST);
    try {
      const { data } = await apolloClient.query({
        query: getCustomerPeppol,
        variables: params
      });

      commit(MUTATIONS.GET_CUSTOMER_PEPPOL_SUCCESS, data.getCustomerPeppol);
    } catch (error) {
      commit(MUTATIONS.GET_CUSTOMER_PEPPOL_FAILURE, error);
    }
  },

  // get Tenant Integration Storecove
  async [ACTIONS.GET_TENANT_INTEGRATION_STORECOVE]({ commit }, params) {
    commit(MUTATIONS.GET_TENANT_INTEGRATION_STORECOVE_REQUEST);
    try {
      const { data } = await apolloClient.query({
        query: getTenantIntegrationStorecove,
        variables: params
      });

      commit(MUTATIONS.GET_TENANT_INTEGRATION_STORECOVE_SUCCESS, data.getTenantIntegrationStorecove);
    } catch (error) {
      commit(MUTATIONS.GET_TENANT_INTEGRATION_STORECOVE_FAILURE, error);
    }
  },

  // Repeat order
  async [ACTIONS.REPEAT_ORDER_SALE]({ commit, state }, params) {
    commit(MUTATIONS.REPEAT_ORDER_SALE_REQUEST);
    try {
      const { data, errors } = await apolloClient.mutate({
        mutation: repeatOrderItems,
        variables: params
      });
      if (errors) {
        commit(MUTATIONS.REPEAT_ORDER_SALE_FAILURE, errors[0]);
      } else {
        commit(MUTATIONS.REPEAT_ORDER_SALE_SUCCESS, data.repeatOrderItems);
        state.unAvailableRepeatSkuIds = data.repeatOrderItems.unAvailableSkuIds;
        state.isRepeatOrderSuccess = data.repeatOrderItems.success;
      }
    } catch (error) {
      commit(MUTATIONS.REPEAT_ORDER_SALE_FAILURE, error);
    }
  },

  async [ACTIONS.UPDATE_ORDER_SALE]({ commit }, params) {
    commit(MUTATIONS.UPDATE_ORDER_REQUEST);
    try {
      const { data, errors } = await apolloClient.mutate({
        mutation: saleUpdateOrder,
        variables: params
      });
      if (!errors) {
        commit(MUTATIONS.UPDATE_ORDER_SUCCESS, data.saleUpdateOrder);
      } else {
        commit(MUTATIONS.UPDATE_ORDER_FAILURE, errors[0]);
      }
    } catch (error) {
      commit(MUTATIONS.UPDATE_ORDER_FAILURE, getMessage(error));
    }
  },

  async [ACTIONS.CANCEL_ORDER_SALE]({ commit }, params) {
    commit(MUTATIONS.CANCEL_ORDER_REQUEST);
    try {
      const { data, errors } = await apolloClient.mutate({
        mutation: saleCancelOrder,
        variables: params
      });
      if (!errors) {
        commit(MUTATIONS.CANCEL_ORDER_SUCCESS, data.saleCancelOrder);
      } else {
        commit(MUTATIONS.CANCEL_ORDER_FAILURE, errors[0]);
      }
    } catch (error) {
      commit(MUTATIONS.CANCEL_ORDER_FAILURE, getMessage(error));
    }
  },

  //sale get total count
  async [ACTIONS.SALE_GET_TOTAL_COUNT_ORDER]({ commit, state }, params) {
    try {
      const { data } = await apolloClient.query({
        query: saleGetTotalCountOrder,
        variables: { ...state.params, ...params }
      });
      commit(MUTATIONS.SALE_GET_TOTAL_COUNT_ORDER_SUCCESS, data.saleGetTotalCountOrder);
    } catch (error) {
      commit(MUTATIONS.SALE_GET_TOTAL_COUNT_ORDER_FAILURE, error);
    }
  }
};
