import { apolloClient } from '@/main';
import { getMessage } from '@/services/shared/helper/services.js';
import { MUTATIONS } from './mutations';

import {
  PRICE_ADD,
  cancelOrderB2b,
  getCustomerPeppol,
  getCustomersOrders,
  getInvoiceList,
  getIsEnableSoftBlockOrder,
  getOrderItemAmendments,
  getOrderItemDetailB2b,
  getPurchaseB2bOrders,
  getTenantIntegrationStorecove,
  getTotalCountPurchaseB2bOrder,
  orderItemDisputes,
  sendInvoiceToPeppolByOrderId,
  updateItemReceived,
  updateOrderB2b,
  updateOrderItemAmendmentsApprovalById
} from '@/modules/b2b/services/graphql';

import { repeatOrderItems } from '@/services/shared/graphql';

export const ACTIONS = {
  GET_PURCHASE: 'GET_PURCHASE',
  LOAD_MORE_PURCHASE: 'LOAD_MORE_PURCHASE',
  RELOAD_PURCHASE: 'RELOAD_PURCHASE',

  GET_PURCHASE_DETAIL: 'GET_PURCHASE_DETAIL',

  ORDER_ITEM_DISPUTES: 'ORDER_ITEM_DISPUTES',
  GET_LIST_INVOICE: 'GET_LIST_INVOICE',

  SEND_INVOICE_TO_MAIL_BY_ORDER_ID: 'SEND_INVOICE_TO_MAIL_BY_ORDER_ID',

  SEND_INVOICE_TO_PEPPOL_BY_ORDER_ID: 'SEND_INVOICE_T0_PEPPOL_BY_ORDER_ID',
  GET_CUSTOMER_PEPPOL: 'GET_CUSTOMER_PEPPOL',
  GET_TENANT_INTEGRATION_STORECOVE: 'GET_TENANT_INTEGRATION_STORECOVE',

  REPEAT_ORDER_B2B: 'REPEAT_ORDER_B2B',
  UPDATE_ORDER_B2B: 'UPDATE_ORDER_B2B',
  CANCEL_ORDER_B2B: 'CANCEL_ORDER_B2B',

  GET_VIEWS_PRICES: 'GET_VIEWS_PRICES',
  PRICE_ADD: 'PRICE_ADD',

  GET_ORDER_ITEM_AMENDMENTS: 'GET_ORDER_ITEM_AMENDMENTS',
  UPDATE_AMENDMENT: 'UPDATE_AMENDMENT',
  UPDATE_ITEM_RECEIVED: 'UPDATE_ITEM_RECEIVED',
  B2B_GET_CUSTOMERS_ORDERS: 'B2B_GET_CUSTOMERS_ORDERS',

  GET_TOTAL_COUNT_PURCHASE_B2B_ORDER: 'GET_TOTAL_COUNT_PURCHASE_B2B_ORDER',
  CHECK_IS_ENABLE_SOFT_BLOCK_ORDER: 'CHECK_IS_ENABLE_SOFT_BLOCK_ORDER'
};

export default {
  async [ACTIONS.GET_PURCHASE]({ commit, state }, { params, isLoadMore = false, onFinish }) {
    commit(MUTATIONS.GET_PURCHASE_REQUEST, isLoadMore);
    try {
      const { data } = await apolloClient.query({
        query: getPurchaseB2bOrders,
        variables: { ...state.params, ...params }
      });

      // get total count
      if (state.params.isIncludeTotalCount) state.totalCount = data.getPurchaseB2bOrders.totalCount;

      if (onFinish) onFinish();
      commit(MUTATIONS.GET_PURCHASE_SUCCESS, { list: data.getPurchaseB2bOrders });
    } catch (error) {
      commit(MUTATIONS.GET_PURCHASE_FAILURE, error);
    }
  },
  async [ACTIONS.LOAD_MORE_PURCHASE]({ commit, dispatch }, { params, isLoadMore = true, onFinish }) {
    // update search params
    commit(MUTATIONS.UPDATE_QUERY_PARAMS, { params });
    dispatch(ACTIONS.GET_PURCHASE, { isLoadMore, onFinish });
  },

  async [ACTIONS.RELOAD_PURCHASE]({ commit, dispatch }, variables) {
    // update search params
    commit(MUTATIONS.UPDATE_QUERY_PARAMS, {
      params: {
        isIncludeTotalCount: true,
        offset: 0,
        isLoadMore: false,
        ...variables
      }
    });

    dispatch(ACTIONS.GET_PURCHASE, {});
  },

  // Get detail purchase
  async [ACTIONS.GET_PURCHASE_DETAIL]({ commit }, params) {
    commit(MUTATIONS.GET_PURCHASE_DETAIL_REQUEST);
    try {
      const { data } = await apolloClient.query({
        query: getOrderItemDetailB2b,
        variables: params
      });

      commit(MUTATIONS.GET_PURCHASE_DETAIL_SUCCESS, { list: data.getOrderItemDetailB2b });
    } catch (error) {
      commit(MUTATIONS.GET_PURCHASE_DETAIL_FAILURE, error);
    }
  },

  // order item Disputes
  async [ACTIONS.ORDER_ITEM_DISPUTES]({ commit }, params) {
    commit(MUTATIONS.ORDER_ITEM_DISPUTES_REQUEST);
    try {
      const { data } = await apolloClient.mutate({
        mutation: orderItemDisputes,
        variables: params
      });
      commit(MUTATIONS.ORDER_ITEM_DISPUTES_SUCCESS, data.orderItemDisputes);
    } catch (error) {
      commit(MUTATIONS.ORDER_ITEM_DISPUTES_FAILURE, error);
    }
  },

  // get list invoice
  async [ACTIONS.GET_LIST_INVOICE]({ commit }, params) {
    commit(MUTATIONS.GET_LIST_INVOICE_REQUEST);
    try {
      const { data } = await apolloClient.query({
        query: getInvoiceList,
        variables: params
      });

      commit(MUTATIONS.GET_LIST_INVOICE_SUCCESS, { list: data.getInvoiceList });
    } catch (error) {
      commit(MUTATIONS.GET_LIST_INVOICE_FAILURE, error);
    }
  },

  // Sen invoice to pepol by order_id
  async [ACTIONS.SEND_INVOICE_TO_PEPPOL_BY_ORDER_ID]({ commit }, params) {
    commit(MUTATIONS.SEND_INVOICE_TO_PEPPOL_BY_ORDER_ID_REQUEST);
    try {
      const { data } = await apolloClient.mutate({
        mutation: sendInvoiceToPeppolByOrderId,
        variables: params
      });

      commit(MUTATIONS.SEND_INVOICE_TO_PEPPOL_BY_ORDER_ID_SUCCESS, data.sendInvoiceToPeppolByOrderId);
    } catch (error) {
      commit(MUTATIONS.SEND_INVOICE_TO_PEPPOL_BY_ORDER_ID_FAILURE, error);
    }
  },

  // get customer peppol
  async [ACTIONS.GET_CUSTOMER_PEPPOL]({ commit }, params) {
    commit(MUTATIONS.GET_CUSTOMER_PEPPOL_REQUEST);
    try {
      const { data } = await apolloClient.query({
        query: getCustomerPeppol,
        variables: params
      });

      commit(MUTATIONS.GET_CUSTOMER_PEPPOL_SUCCESS, data.getCustomerPeppol);
    } catch (error) {
      commit(MUTATIONS.GET_CUSTOMER_PEPPOL_FAILURE, error);
    }
  },

  // get Tenant Integration Storecove
  async [ACTIONS.GET_TENANT_INTEGRATION_STORECOVE]({ commit }, params) {
    commit(MUTATIONS.GET_TENANT_INTEGRATION_STORECOVE_REQUEST);
    try {
      const { data } = await apolloClient.query({
        query: getTenantIntegrationStorecove,
        variables: params
      });

      commit(MUTATIONS.GET_TENANT_INTEGRATION_STORECOVE_SUCCESS, data.getTenantIntegrationStorecove);
    } catch (error) {
      commit(MUTATIONS.GET_TENANT_INTEGRATION_STORECOVE_FAILURE, error);
    }
  },

  // Repeat order
  async [ACTIONS.REPEAT_ORDER_B2B]({ commit, state }, params) {
    commit(MUTATIONS.REPEAT_ORDER_B2B_REQUEST);
    try {
      const { errors, data } = await apolloClient.mutate({
        mutation: repeatOrderItems,
        variables: params
      });
      if (errors) {
        commit(MUTATIONS.REPEAT_ORDER_B2B_FAILURE, errors[0]);
      } else {
        commit(MUTATIONS.REPEAT_ORDER_B2B_SUCCESS);
        state.unAvailableRepeatSkuIds = data.repeatOrderItems.unAvailableSkuIds;
        state.isRepeatOrderSuccess = data.repeatOrderItems.success;
      }
    } catch (error) {
      commit(MUTATIONS.REPEAT_ORDER_B2B_FAILURE, error);
    }
  },

  async [ACTIONS.UPDATE_ORDER_B2B]({ commit }, params) {
    commit(MUTATIONS.UPDATE_ORDER_B2B_REQUEST);
    try {
      const { data, errors } = await apolloClient.mutate({
        mutation: updateOrderB2b,
        variables: params
      });
      if (!errors) {
        commit(MUTATIONS.UPDATE_ORDER_B2B_SUCCESS, data.updateOrderB2b);
      } else {
        commit(MUTATIONS.UPDATE_ORDER_B2B_FAILURE, errors[0]);
      }
    } catch (error) {
      commit(MUTATIONS.UPDATE_ORDER_B2B_FAILURE, getMessage(error));
    }
  },

  async [ACTIONS.CANCEL_ORDER_B2B]({ commit }, params) {
    commit(MUTATIONS.CANCEL_ORDER_REQUEST);
    try {
      const { data, errors } = await apolloClient.mutate({
        mutation: cancelOrderB2b,
        variables: params
      });
      if (!errors) {
        commit(MUTATIONS.CANCEL_ORDER_SUCCESS, data.cancelOrderB2b);
      } else {
        commit(MUTATIONS.CANCEL_ORDER_FAILURE, errors[0]);
      }
    } catch (error) {
      commit(MUTATIONS.CANCEL_ORDER_FAILURE, getMessage(error));
    }
  },

  // get views prices
  async [ACTIONS.PRICE_ADD]({ commit }, params) {
    commit(MUTATIONS.PRICE_ADD_REQUEST);
    try {
      const { data } = await apolloClient.mutate({
        mutation: PRICE_ADD,
        variables: params
      });

      commit(MUTATIONS.PRICE_ADD_SUCCESS, data.priceAdd);
    } catch (error) {
      commit(MUTATIONS.PRICE_ADD_FAILURE, error);
    }
  },

  async [ACTIONS.GET_ORDER_ITEM_AMENDMENTS]({ commit }, params) {
    commit(MUTATIONS.GET_ORDER_ITEM_AMENDMENTS_REQUEST);
    try {
      const { data } = await apolloClient.query({
        query: getOrderItemAmendments,
        variables: params
      });

      commit(MUTATIONS.GET_ORDER_ITEM_AMENDMENTS_SUCCESS, data.getOrderItemAmendments);
    } catch (error) {
      commit(MUTATIONS.GET_ORDER_ITEM_AMENDMENTS_FAILURE, error);
    }
  },

  async [ACTIONS.UPDATE_AMENDMENT]({ commit }, params) {
    commit(MUTATIONS.UPDATE_AMENDMENT_REQUEST);
    try {
      const { data } = await apolloClient.mutate({
        mutation: updateOrderItemAmendmentsApprovalById,
        variables: params
      });

      commit(MUTATIONS.UPDATE_AMENDMENT_SUCCESS, data.updateOrderItemAmendmentsApprovalById);
    } catch (error) {
      commit(MUTATIONS.UPDATE_AMENDMENT_FAILURE, error);
    }
  },

  async [ACTIONS.UPDATE_ITEM_RECEIVED]({ commit }, params) {
    commit(MUTATIONS.UPDATE_ITEM_RECEIVED_REQUEST);
    try {
      const { data } = await apolloClient.mutate({
        mutation: updateItemReceived,
        variables: params
      });

      commit(MUTATIONS.UPDATE_ITEM_RECEIVED_SUCCESS, data.updateItemReceived);
    } catch (error) {
      commit(MUTATIONS.UPDATE_ITEM_RECEIVED_FAILURE, error);
    }
  },
  async [ACTIONS.B2B_GET_CUSTOMERS_ORDERS]({ commit }, params) {
    try {
      const { data } = await apolloClient.query({
        query: getCustomersOrders,
        variables: params
      });
      commit(MUTATIONS.B2B_GET_CUSTOMERS_ORDERS_SUCCESS, { list: data.getCustomersOrders });
    } catch (error) {
      commit(MUTATIONS.B2B_GET_CUSTOMERS_ORDERS_FAILURE, error);
    }
  },
  async [ACTIONS.GET_TOTAL_COUNT_PURCHASE_B2B_ORDER]({ commit, state }, params) {
    try {
      const { data } = await apolloClient.query({
        query: getTotalCountPurchaseB2bOrder,
        variables: { ...state.params, ...params }
      });
      commit(MUTATIONS.GET_TOTAL_COUNT_PURCHASE_B2B_ORDER_SUCCESS, data.getTotalCountPurchaseB2bOrder);
    } catch (error) {
      commit(MUTATIONS.GET_TOTAL_COUNT_PURCHASE_B2B_ORDER_FAILURE, error);
    }
  },
  async [ACTIONS.CHECK_IS_ENABLE_SOFT_BLOCK_ORDER]({ commit }, params) {
    try {
      const { data } = await apolloClient.query({
        query: getIsEnableSoftBlockOrder,
        variables: {
          customerId: params.customerId,
          tenantId: params.tenantId
        }
      });
      commit(MUTATIONS.CHECK_IS_ENABLE_SOFT_BLOCK_ORDER_SUCCESS, data.getIsEnableSoftBlockOrder);
    } catch (error) {
      commit(MUTATIONS.CHECK_IS_ENABLE_SOFT_BLOCK_ORDER_FAILURE, error);
    }
  }
};
