import gql from 'graphql-tag';
import { TenantFragment } from './fragment';

export const getCustomerTenants = gql`
  query getCustomerTenants($customerId: Int!) {
    getCustomerTenants(customerId: $customerId) {
      ...TenantFragment
    }
  }
  ${TenantFragment}
`;

export const getPicConnectByPinConfig = gql`
  query getPicConnectByPinConfig {
    getPicConnectByPinConfig
  }
`;

export const checkPersonByPhoneNumber = gql`
  query checkPersonByPhoneNumber($phoneNumber: String!, $customerId: Int!) {
    checkPersonByPhoneNumber(phoneNumber: $phoneNumber, customerId: $customerId) {
      id
      phone
      active
    }
  }
`;
