const en = {
  about_app: 'About App',
  about_this_product: 'About This Product',
  about_treedots: 'About TreeDots',
  about_us: 'About Us',
  access_denied: 'Access denied!',
  access_signup_des1: 'Looks like you do not have an account with us.',
  access_signup_des2: 'Please create an account or ',
  access_signup_des3: 'to Add to Cart.',
  access_signup_terms: 'I have read and agree to the ',
  account: 'Account',
  account_type: 'Account Type',
  register_account_type: 'You are registering as:',
  personal: 'Personal',
  business: 'Business',
  register_personal: 'To purchase for personal use',
  register_business: 'To purchase for business use',
  accountPage: {
    add_card: 'Add Card',
    edit_card: 'Edit Card',
    added_card_successfully: 'Card has been added!',
    are_you_sure_delete_card: 'Are you sure you want to delete this credit card?',
    are_you_sure_delete_card_nets: 'Are you sure you want to delete this NETS bank card?',
    nets_card_error_title: 'NETS bank card error',
    nets_card_error_instruction:
      'NETS bank card is not available for this devices we will clear all NETS bank card on this device, please add new card again',
    nets_card_not_allow: 'NETS bank card is not allow with the payment has more than 1 tenant',
    cannot_be_empty: 'Cannot be empty',
    card_has_been_removed: 'Card has been removed',
    card_number: 'Card Number',
    add_nets_card_type: 'Add NETS Bank Card',
    change: 'Change',
    configure_peppol_identifier: 'Configure Peppol Identifier',
    confirm_new_password: 'Confirm new password',
    confirm_new_password_cannot_be_empty: 'Confirm new password cannot be empty',
    confirm_password_does_not_match: 'Confirm password does not match',
    cvc_cvv: 'CVC/CVV',
    cvv: 'CVV',
    default: 'Default',
    default_card_has_been_changed: 'Default card has been changed',
    delete_card: 'Delete Card',
    dont_have_card: 'Don’t have a card? Click here to apply and get $200 cashback!',
    enter_current_password: 'Enter current password',
    enter_new_password: 'Enter new password',
    expiry_date: 'Expiry Date',
    manage_companines: 'Manage Companies',
    must_be_a_peppol_identifier: 'Must be a peppol identifier',
    must_be_a_valid_number: 'Must be a valid number',

    nets_card_type: '(NETS Bank Card)',
    new_password_cannot_be_empty: 'New password cannot be empty',
    old_password_cannot_be_empty: 'Old password cannot be empty',
    old_password_doesnt_match: "Old password doesn't match",
    password: 'Password',
    password_must_be_at_least_6_characters: 'Password must be at least 6 characters',
    peppol_id_configuration: 'Peppol ID Configuration',
    peppol_id_configured_successfully: 'Peppol ID configured successfully',
    peppol_identifier: 'Peppol Identifier',
    peppol_scheme: 'Peppol Scheme',
    peppol_scheme_description: 'Peppol Scheme Description',
    please_choose_something: 'Please choose something',
    product_sku_name: 'Product Sku Name',
    remarks: 'Remarks',
    remove: 'Remove',
    request_product: 'Request Product',
    request_product_success: 'Request product success',
    set_as_default: 'Set as default',
    supplier_name: 'Supplier Name',
    validity_period: 'Validity Period',
    your_password_has_been_saved: 'Your password has been saved'
  },
  add: 'Add',
  add_address: 'Add Address',
  add_another_card: 'Add Another Card',
  add_card_failed: {
    expired_card: 'This card has expired. Please try again with another card.',
    general:
      'There seems to be a problem with your card. Please try again with another card or reach out to your bank for further assistance.',
    invalid_account:
      'This card is invalid. Please add a different card or reach out to your bank for further assistance.',
    stolen_card:
      'There seems to be a problem with your card. Please reach out to your bank for further assistance.',
    card_number_invalid: 'Enter a valid card number',
    expired_date_invalid: 'Enter a valid expiry date (MM/YY)',
    cvc_invalid: 'Enter a valid CVC/CVV number'
  },
  add_card_or_credit_card: 'Add Debit/Credit Card',
  add_card_success: 'Add new card successfully!',
  add_card_validation: {
    card_number: 'Enter a valid 16-digits card number',
    cvv: 'Invalid 3-digit CVV',
    expiry_date: 'MM/YY',
    invalid_month: 'Invalid Month'
  },
  add_item_cart: 'Add Item To Cart',
  add_items_to_favorite_tab: 'Add them to Favourites for faster ordering and checkout in future.',
  add_new_address: 'Add New Address',
  add_new_card: 'Add New Card',
  add_delivery_instructions_or_directions: 'Enter delivery instructions or directions here',
  add_self_pickup_instructions: 'Add self-pickup instructions here',
  add_product_to_favorite: 'Add products to Favourites?',
  add_to_cart: 'Add to Cart',
  addCompany: {
    accept: 'Accept',
    accept_company_request: 'Accept Company Request',
    companies_that_assigned:
      'companies that assigned you as key contact. Please review and acccept or reject.',
    have_been_notified:
      'has been notified of your action and a member of our operations team will contact you soon to confirm this action before removing you as',
    key_contact_person: 'key contact person.',
    my_companies: 'My Companies',
    pending_your_action: 'Pending your action',
    reject_company_request: 'Reject Company Request',
    success_assigned: 'Successfully Assigned',
    success_rejected: 'Successfully Rejected',
    there_are: 'There are',
    // key contact person for
    would_you_like: 'Would you like to confirm yourself as ',
    would_you_like_to_reject_being: 'Would you like to reject being',
    you_are_now: 'You are now',
    you_can_now_make_order: 'You can now make orders for'
  },
  adding_card_failed: 'Adding card failed',
  additional_options: 'Additional Options',
  address: 'Address',
  address_btn_remove: `Delete Address`,
  address_remove_confirm: 'Delete this address?',
  address_unable_delete_default: 'Unable to delete default billing address',
  address_form: {
    city: {
      required: 'City cannot be empty'
    },
    country: {
      required: 'Country can not be empty'
    },
    postal_code: {
      required: 'Postal code cannot be empty'
    },
    road: {
      required: 'Street cannot be empty'
    },
    state: {
      required: 'State cannot be empty'
    }
  },
  alert_blacklist_order: 'alert-blacklist-order',
  alert_dulicate_order: 'alert-duplicate-order',
  all: 'All',
  all_collection_point: 'All Collection Points',
  all_products: 'All Products',
  all_rights_reserved: 'All Rights Reserved',
  allow_location_access: 'Allow Location Access',
  already_have_an_account: 'Already have an account?',
  and: ' and ',
  apply: 'Apply',
  apply_treedots_voucher: 'Apply TreeDots Voucher',
  apply_voucher: 'Apply voucher',
  approve: 'Approve',
  approvedBy: 'Approved By',
  approvedPrice: 'Approved Price',
  are_you_sure: 'Are you sure?',
  Available: 'Available',
  available_vouchers: 'vouchers available',
  available_vouchers_for_you: 'Available Vouchers for you',
  back: 'Back',
  back_order: 'Back Order',
  back_to_top: 'Back to Top',
  back_to_try_again: 'Back to Try Again',
  back_try_again: 'Back to Try Again',
  began: 'How it all began',
  billing_address: 'Billing Address',
  birth_date: 'Date of Birth',
  birth_date_failed: 'Date of Birth update is failed!',
  birth_date_updated: 'Date of Birth updated successfully!',
  branches: 'Branches',
  bookings: 'Bookings',
  booking_date: 'Booking Date',
  we_will_proceed_to_clear_your_cart: 'We will proceed to clear your cart',
  successfully_added_to_cart: 'Successfully added to cart.',
  total_order: 'Total Order',
  total_weight: 'Total Weight',
  save: 'Save',
  update_price: 'Update Price',
  success: 'Success',
  transaction: 'Transaction',
  drn_goods_spoild: 'Goods Spoiled',
  drn_missing_item: 'Missing Item',
  drn_delayed_delivery: 'Delayed Delivery',
  dry_request_exchange: 'Requesting for 1:1 exchange',
  dry_request_credit: 'Requesting for Credit Note',
  dry_request_pick_item: 'Requesting to pick up wrong item',
  dry_no_remedy: 'Accepting the product without any remedy',
  select_valid_action: 'Please select a valid reason for your problem',
  select_valid_remedy: 'Please select a valid remedy action for your problem',
  dispute_selected_reason: 'Selected reason for your problem',
  dispute_status_resolved: 'Resolved',
  dispute_status_declined: 'Declined',
  view_dispute_status: 'View Dispute Status',
  something_went_wrong_please_try_again: 'Something went wrong, Please try again!',
  user_not_found_for_mobile: 'You do not have an account with us. Please register to access our platform.',
  go_to_login: 'Go to login',
  welcome_again: 'Welcome Again!',
  browse: 'Browse',
  browse_message_if_cart_empty: 'our foods to add your items here!',
  browse_more: 'Browse more items in ',
  building_name: 'Building Name',
  cache_cleared: 'Cache cleared.',
  cancel: 'Cancel',
  cancel_button: 'cancel-button',
  cancel_leave_community_chat: 'CANCEL',
  cancel_order_item_hint: 'You cannot cancel items when group buy is achieved',
  cancel_pre_order: 'Cancel Pre Order',
  cancel_remove_product_form_cart: 'No, Keep This Item',
  cancelled_item: 'You cancelled an item.',
  cancelOrder: 'Cancel Order',
  order_item: 'Order Item:',
  select_reason: 'Select Reason',
  let_us_know: 'Let us know why you are changing your order.',
  additional_info: 'Reason',
  required: '(Required)',
  input_your_reasons: 'Input your reasons',
  input_editional_info: 'Additional Information',
  minimum_5_char: 'Minimum 5 characters!',
  confirm_cancel: 'Confirm Cancellation',
  cannot_connect: 'Cannot Connect',
  cannot_create_problem: 'Can not create your request at the moment, Please try again',
  cant_ordered: "Sorry some items can't be ordered anymore. We have removed them from your order.",
  card: 'Card',
  nets_bank_card: 'NETS Bank Card',
  card_default_failed: 'Set card as default is failed!',
  card_default_success: 'Set card as default successfully!',
  card_list: 'Card List',
  card_not_selected: 'Not Selected',
  card_popover: 'card-popover',
  card_unset_default_failed: 'Unset card as default is failed!',
  card_unset_default_success: 'Unset card as default successfully!',
  cart: 'Cart',
  cart_error_title: 'Card Error!',
  carton: 'CARTON',
  carton_deal: 'CARTON DEAL',
  cash_on_delivery: 'Cash on Delivery',
  paynow: 'PayNow',
  fluid: 'Fluid',
  categories: 'Categories',
  category_sub: 'Category/ Sub Category',
  cc_add_card: 'Add New Card',
  cc_card_number: 'Card Number',
  cc_cvv: 'CVV',
  cc_expiry_date: 'Expiry Date',
  cc_name_on_card: 'Name On Card',
  change: 'Change',
  change_address: 'Change Address',
  change_order_item_status_confirmation: 'Do you want to update Item Order Status ?',
  change_password: 'Change Password',
  change_profile_photo: 'Change profile photo',
  change_the_address: 'Change the address',
  change_time: 'Change time',
  channel_admin: 'Channel Admin',
  chat: 'Chat',
  chat_mute_notification: 'Mute Notification',
  chat_muted: 'Chat muted',
  chat_no_description: 'Host of this collection point has not added a group description yet. Stay tuned!',
  chat_participants: 'Members',
  chat_unmuted: 'Chat unmuted',
  chats: 'Chats',
  check_birtday_val: 'updateProfile.birth_date_cannot_be_empty',
  check_email_val: 'updateProfile.email_cannot_be_empty',
  check_fname_length: 'first_name_cannot_be_empty',
  check_fname_length_limit: 'first_name_must_be_at_least_2_characters',
  check_gender_val: 'updateProfile.gender_cannot_be_empty',
  check_phone_number: 'Phone number already exists.',
  check_phone_number_error: 'Invalid Phone Number.',
  check_phone_number_length_val: 'updateProfile.phone_number_must_be_at_least_5_characters',
  check_phone_number_val: 'phone_number_cannot_be_empty',
  check_search: 'Please check your',
  check_valid_close_time: 'Field cannot be empty',
  check_valid_email: 'this_field_must_be_a_valid_email',
  check_valid_open_time: 'Field cannot be empty',
  checkout: 'Checkout',
  checkout_loader: {
    back_to_checkout: 'Back to Checkout',
    cancel_transaction: 'You’ve canceled your transaction.',
    change_payment: 'Change Payment Method',
    contact_support: 'Contact Support',
    continue: 'Continue',
    fail: 'Your payment was unsuccessful.<br>Please try again!',
    failure: {
      card_not_supported:
        'This card is not supported for this transaction. Please checkout again with another card.',
      card_velocity_exceeded:
        'Card limit is exceeded. Please try again with another card to complete the checkout.',
      error: 'Something went wrong...We are having issues with loading this page. Please try again.',
      expired_card: 'Your card has expired. Please try again with another card.',
      general:
        'There seems to be a problem with your card. Please try again with another card to complete the transaction or reach out to your bank for further assistance.',
      insufficient_e_wallet_balance:
        'Your balance is insufficient to pay. Please make sure you have enough funds on your TreeDots Pay and try again',
      insufficient_funds:
        'Your balance is insufficient to pay. Please make sure you have enough funds on your payment method or choose another.',
      invalid_account: 'Your card is no longer valid. Please try again with another card.',
      processing_error: 'An error occurred while processing the card. Please try to checkout again.'
    },
    insufficient: 'Insufficient funds.',
    loading: 'Please wait!<br>We’re processing your payment...',
    not_you_1: 'It’s not you. It’s us.',
    not_you_2: 'Please reach our',
    not_you_3: 'Support Team',
    not_you_4: 'for assistance!',
    or_reach_1: 'Or reach our',
    or_reach_2: 'Support Team',
    or_reach_3: 'for help',
    payment_canceled: 'Payment canceled',
    payment_failed: 'Payment failed!',
    review_checkout: 'Review Checkout Items',
    review_payment: 'Review Payment Method',
    success: 'Your payment was successful!',
    try_another_method: 'Try another payment method?'
  },
  checkout_pre_order: 'Checkout Pre Order',
  chinese_simplified: 'Chinese (Simplified)',
  chinese_traditional: 'Chinese (Traditional)',
  choose_card: 'Need To Choose The Card',
  choose_hub: 'Search Collection Points',
  choose_hub_btn: 'Choose Collection Point',
  choose_product: 'Need To Choose The Product',
  choose_server: 'Choose Server',
  choose_sign_in_method: 'Choose sign in method',
  city: 'City',
  city_cannot_be_empty: 'City cannot be empty',
  clear_cache: 'Clear Cache',
  clear_cache_confirm: 'Are you sure to clear the cache?',
  close: 'Close',
  CLOSE: 'CLOSE',
  code: 'Code',
  collect_at: 'Collect At',
  collection_date: 'Estimated Collection Date: %{date}',
  collection_details: 'Collection Details',
  collection_note:
    '*Collection Date will be confirmed once your Collection Point hits %{currency_symbol}%{minimum_order}.',
  collection_point_description:
    'Collection Points are places within your community and other locations where you can do the following:',
  collection_point_not_supported:
    'We’re sorry. This supplier does not deliver to your selected Collection Point.',
  collection_point_unsupported: 'Collection Point not supported',
  collection_points_near_you: 'Collection Points Near You',
  coming_soon: 'coming soon!',
  community: 'Community',
  community_description_label: 'Community Description',
  companies_with_key_contact: 'Companies with Key Contact',
  companies_with_postal_code: 'Companies with postal code',
  company: 'Company',
  companyDoesNotExit: 'Company does not exist',
  confirm: 'Confirm',
  confirm_button: 'confirm-button',
  confirm_cancel_order: 'Are you sure to cancel this order?',
  confirm_CP_modal: 'Join our Hub Community to receive updates on order and delivery!',
  confirm_CP_title: 'Welcome to Ang Mo Kio Hubs',
  confirm_email: 'Confirm New Email',
  confirm_increased_quantity: 'Confirm Increased Quantity',
  confirm_order_5_mins: 'was created within the past 5 mins, do you want to submit this new order?',
  confirm_password: 'Confirm New Password',
  confirm_password_cannot_be_empty: 'Confirm password cannot be empty',
  confirm_password_does_not_match: 'Confirm password does not match',
  confirm_password_new: 'Confirm Password',
  confirm_remove_product_form_cart: 'Yes, Remove This Item',
  confirm_send_inv_email: 'We will send invoice(s) to your email, continue?',
  confirm_submission: 'Confirm Submission',
  confirm_update_order: 'Are you sure to update this pre order?',
  confirm_update_order_mess: 'Are you sure to update this order?',
  confirmation: 'Confirmation',
  connect: 'Connect',
  connected: 'Connected',
  not_connected: 'Not Connected',
  connectSupplier: {
    connected: 'Connected',
    payment: 'Payment',
    supplier: 'supplier',
    supplier_not_found: 'Supplier Not Found'
  },
  connectToOtherSuppliers: 'Connect to other suppliers',
  contact: 'Contact',
  contact_p1: 'For customers in Indonesia',
  contact_p2: "Customer Service Email: hello.id{'@'}thetreedots.id",
  contact_p3: 'WhatsApp: +62 821 10001 778',
  contact_p4: 'Layanan Pengaduan Konsumen',
  contact_p5: 'Direktorat Jenderal Perlindungan Konsumen dan Tertib Niaga',
  contact_p6: 'Kementerian Perdagangan Republik Indonesia',
  contact_p7: 'WhatsApp: 0853-1111-10101',
  contact_us: 'Contact Us',
  continue_order: 'You will place this order, continue ?',
  cooking: 'Cooking',
  copy: 'Copy',
  country: 'Country',
  country_code: 'Country Code',
  country_of_origin: 'Country of Origin',
  cp_description_point_1: 'Group buy with other buyers conveniently',
  cp_description_point_2: 'Select products from different Suppliers',
  cp_description_point_3: 'Pick up your groceries at the time of delivery',
  credit_term: 'Credit Term',
  payment_duedate: 'Payment Due:',
  crop_image: 'Crop Image',
  customerPage: {
    account_number: 'Account Number',
    add_address: 'Add Address',
    add_company: 'Add New Company',
    address: 'Address',
    address_has_been_added: 'Address has been added!',
    address_has_been_updated: 'Address has been updated!',
    address_saved: 'Address saved!',
    bank_transfer: 'Bank Transfer',
    billing_address: 'Billing Address',
    branch: 'Branch',
    business_information: 'Business Information',
    account_information: 'Account Information',
    cannot_load_image: 'Cannot load image',
    cod: 'COD',
    end_week: 'End of Week',
    days_lower: 'days',
    days: 'Days',
    thirty_days: '(30 Days)',
    end_month: 'End of Month',
    collection: 'Collection',
    company: 'Company',
    company_has_been_updated: 'Company has been updated!',
    company_information: 'Company Information',
    company_information_saved: 'Company information saved!',
    company_name: 'Company Name',
    company_name_cannot_be_empty: 'Company name cannot be empty',
    company_name_must_be_at_least_4_characters: 'Company name must be at least 4 characters',
    credit_term: 'Credit Term',
    credit_term_cannot_be_empty: 'Credit Term cannot be empty',
    customer: 'Customer',
    customer_email_optional: 'Customer Email (Optional)',
    customers: 'Customers',
    delete_person: 'Delete Person',
    delivery_charge: ' Delivery charge',
    delivery_hours: 'Delivery Hours',
    delivery_hours_has_been_updated: 'Delivery Hours has been updated!',
    delivery_hours_saved: 'Delivery hours saved!',
    delivery_instruction: 'Delivery Instruction',
    details: 'Details',
    edit_information: 'Edit Information',
    enter_a_location: 'Enter a location',
    enter_building_name: 'Enter building name',
    enter_city: 'Enter city',
    enter_floor_no: 'Enter floor no.',
    enter_new_company_name: 'Enter new company name',
    enter_postal_code: 'Enter postal code',
    enter_stall_no: 'Enter stall no.',
    enter_street_address: 'Enter street address',
    enter_unit_no: 'Enter unit no.',
    enter_your_place: 'Enter your place',
    existing_account: 'Existing Account',
    find_customer: 'Find Customer',
    image: 'Image',
    image_has_been_added: 'Image has been added',
    image_has_been_updated: 'Image has been updated',
    image_optional: 'Image (Optional)',
    images: 'Images',
    minimum_order: 'Minimum Order',
    minimum_order_quantity: 'Minimum Order Quantity',
    new_account: 'New Account',
    no_branches: 'No Branches',
    no_cash_no_delivery: 'No Cash No Delivery',
    person_has_been_added: 'Person has been added!',
    person_has_been_deleted: 'Person has been deleted!',
    person_has_been_updated: 'Person has been updated!',
    person_information: 'Person Information',
    person_information_saved: 'Person information saved!',
    position_cannot_be_empty: 'Position cannot be empty',
    profile_cannot_be_empty: 'Profile cannot be empty',
    select_a_profile: 'Select a profile',
    statement: 'Statement',
    sub_buyer: 'Sub Buyer',
    sub_buyer_has_been_added: 'Sub Buyer has been added!',
    sub_buyers: 'Sub Buyers',
    upload_company_logo: 'Upload Company Logo',
    customer_does_not_have_soa_receiver: `This customer doesn't have any SOA receiver`
  },
  dashboard: 'Dashboard',
  days: '{day} days',
  default: 'Default',
  default_group_description:
    'Host of this collection point has not added a group description yet. Stay tuned!',
  delete: 'Delete',
  delete_account: 'Delete Account',
  delete_account_text1: "We're sorry to see you go",
  delete_account_text2: 'Are you sure you want to delete your account permanently?',
  delete_account_text3: 'Please acknowledge, once you delete:',
  delete_account_text4:
    'We will only retain your account for 30 days, then your account will be permanently deleted. If you change your mind anytime within 30 days, you can reactivate your account by simply logging back in.',
  delete_account_text5:
    'All your data, past transactions, remaining vouchers and e-wallet funds will be permanently deleted and you won’t be able to access your account anymore.',
  delete_card: 'Delete Card?',
  delete_chat_confirm: 'Are you sure you want to leave this chat?',
  delete_items: 'Delete Item(s)',
  price_changes: 'Oh, the price has changed',
  delete_message: 'This message was deleted',
  delete_message_confirm: 'Are you sure you want to delete this message?',
  delivery: 'Delivery',
  delivery_address: 'Delivery Address',
  self_pickup: 'Self Pick-up',
  self_pickup_address: 'Self Pick-up Address',
  delivery_date_time: 'Delivery Date & Time',
  self_pickup_date_time: 'Self Pick-up Date & Time',
  delivery_time: 'Delivery Time',
  self_pickup_time: 'Self Pick-up Time',
  deliveryTimeOptional: 'Delivery Time (Optional)',
  desc_unavailable_product: 'These products are unavailable at your chosen Collection Point.',
  description: 'Description',
  desired_solution: 'Desired solution',
  developer_menu: 'Developer Menu',
  diary: 'Diary',
  did_not_receive_code: 'Didn’t receive code?',
  direct_price: 'Direct Price',
  regular_price: 'Regular Price',
  quoted_price: 'Quoted Price',
  quotation_limit_price: 'Quotation Limit Price',
  price_log_info: 'Company(s) affected by price update',
  following_company: 'The following company(s) have quote(s) that are subject to adjustment',
  load_more: 'Load more',
  view_product: 'View Product',
  from: 'from',
  discount: 'Discount',
  discount_voucher: 'Discount Voucher',
  dispute_select_reason: 'Select the reason for your problem',
  dispute_select_remedy: 'Select the remedy action',
  dispute_selected_remedy: 'Selected remedy',
  dispute_status_processing: 'Processing',
  dispute_status_unknown: 'Unknown',
  distance: 'Distance',
  document: 'Document',
  done: 'Done',
  dont_have_account: "Don't have account",
  dont_have_an_account: "Don't have an Account?",
  downloading: 'Downloading',
  drinks: 'Drinks',
  e_wallet: 'E-Wallet',
  edit: 'Edit',
  edit_card: 'Edit Card',
  edit_profile: 'Edit Profile',
  email: 'Email',
  email_address: 'Email Address',
  email_already_registered: 'This email has already been registered',
  email_already_registered_please_login:
    'This email has already been registered. Please log in with registered email or create using a new one',
  email_alt_img: 'email',
  email_cannot_be_empty: 'Email cannot be empty',
  email_failed: 'Email update is failed',
  email_link_to_share:
    'mailto:?subject=TreeDots%20App%20Download%20Link&amp;body=Hi%2C%20TreeDots%20provides%20high%20quality%20and%20cheap%20inventories.%0A%0ADownload%20now%20at%0Ahttps%3A%2F%2Fthetreedots.com%2Fdownload',
  email_logo_url: 'https://cdn4.iconfinder.com/data/icons/miu-flat-social/60/mail-256.png',
  email_share_link: 'mailto:hello@thetreedots.com',
  email_updated: 'Email updated successfully!',
  email_username: 'Email / Username',
  empty_cart_1: 'Your cart is empty!',
  empty_cart_2: 'Browse',
  empty_cart_3: 'our foods and start ordering with us!',
  encouraging: {
    content_1:
      'In 2021 our group buy platform extended, providing a solution for community pioneers to service end-consumers.',
    content_2: 'We launched',
    content_2a:
      'where Collection Point hosts can manage orders of their neighbourhood, receive bulk goods from suppliers and earn the commission. You can become a host too by signing up for our Seller Center app!',
    seller_app: ' Seller Center app, ',
    title: 'Encouraging Business Ownership'
  },
  english: 'English',
  enter_address: 'Enter address',
  enter_card_number: 'Enter Card Number',
  enter_code: 'Enter Code',
  enter_country: 'Enter country',
  enter_state: 'Enter state',
  enter_voucher_code: 'Enter your voucher code',
  enter_your_mobile_number:
    'Enter your mobile number and we’ll send you a verification code to get back into your account.',
  err_msg_color: 'danger',
  err_query: 'Opps ! An error occurred, try again later.',
  err_query_payment: 'Opps ! something wrong with your payment, please try again later.',
  err_searh: 'There is no search result!',
  err_voucher_invalid: 'The voucher code is invalid!',
  errorContent: 'Sorry, we were unable to process that, please try again.',
  errorDefaultTime: 'Close time should be greater than or equal to Open time',
  errorTitle: 'Oops! An error occurred',
  estimated_collection_date: 'Estimate Collection Date',
  expire_soon: 'Expire Soon',
  expired_date: 'Expired Date',
  expires: 'Expires',
  facebook: 'Facebook',
  facebook_alt_img: 'facebook',
  facebook_link_to_share: 'https://facebook.com/sharer/sharer.php?u=https%3A%2F%2Fthetreedots.com%2Fdownload',
  facebook_logo_url: 'https://cdn4.iconfinder.com/data/icons/miu-flat-social/60/facebook-256.png',
  facing_an_issue_chat_with_us_for_real_time_support: 'Facing any issue? Chat with us for real time support!',
  failed: 'Failed',
  failed_cancel: 'Failed to cancel an item.',
  faq: 'FAQ',
  faq_page: {
    collections: 'Collections',
    more: 'More',
    orders: 'Orders',
    p1_1:
      'To purchase with us, you would first need to download our Group Buy app for mobile and sign up for a personal account. Our app is currently available for IOS and Android. Then start browsing our platform offerings!',
    p1_2:
      'Saw something you like? Select a Collection Point near you from where you’d like to buy and collect your items. Add items to your shopping cart, make sure you have your payment method selected, and place your order - that’s it! Your order will appear under the Order section, where you can monitor the status of your purchase and see once it’s ready to collect. When Group Buy of your & other buyer’s orders altogether achieve a minimum for a supplier to arrange the delivery - you will get an email confirmation on when to pick up your items at your selected Collection Point!',
    p1_3:
      'Don’t forget to access our in-app chat and say “hi” to your collection point Host - it might be your neighbor next door or someone from the area you know! Host will be receiving your goods from suppliers and will have the most up to date information on your order whereabouts. Do make sure to join a Collections Point community chat! There are buyers like you, friends and neighbors, who share the ideas and recipes!',
    p2_1:
      'Our platform offers a Group Buy shopping experience where friends and neighbours shop together, save money and food together! When you buy products with us, you become a part of a Group Buy automatically and contribute to solving the food loss issue with your every purchase!',
    p2_2:
      'How does it work? All products listed by our suppliers are sold in bulk. But the good news is - you don’t have to buy this bulk alone! We allow individual buyers to buy items in quantities they want which we consolidate with other buyers purchases in a Group Buy. When products bought by buyers altogether achieve the required minimum, suppliers arrange the delivery of all orders to the Collection Point at once.',
    p2_3:
      'This way we can keep our prices low, so you can enjoy up to 90% off retail prices on your groceries! And suppliers don’t have to break their bulk or waste any inventory allowing for more effective means of redistribution and reduction of carbon emissions into the atmosphere. What’s more? We bring communities together! Join our in-app community chat and say “hi” to your Collection Point Host and fellow buyers!',
    p3_1:
      'To join a Collection Point, start from searching on map the collection points nearby your place. You can access the map by clicking on the location icon. Once you found Collection Point that you want to buy and collect your items from, click confirm and enjoy browsing products available there!',
    p3_2:
      'When you join a Collection Point, you become a part of Collection Point community chat automatically, where you can talk to the Host regarding your order and collection or chat with fellow buyers! Want to talk to your Host privately? 1:1 chat is available too! To access all chats, select the chat icon.',
    p4_1:
      'You can monitor the status of your order via app under the Order section. Items that are ready for collection will appear under “To Collect” status with confirmed collection date. Think you might miss it? Don’t worry, we will send you an email and notification once the collection date for your items is confirmed. And when the day has come, we will remind you again!',
    p4_2:
      'Please note, that items from different suppliers in your order may arrive at a different times depending on the Group Buy progress. If you are unable to collect your items on a specific date, please talk to your collection point Host via in-app chat if you’d like to make any amendment to your order.',
    p5_1:
      'You can cancel items in your order if Group Buy minimum for the supplier is not achieved. You can check Group Buy progress of your products in the Order section. Items with status “To Collect” with confirmed collection date can not be cancelled due to items change of mind reasons. We process refunds for canceled immediately after cancellation. Please, note that it might take 7-10 business days for a refund to appear in your bank account. For full information on cancelation and refunds policy, check our Refunds section.',
    p6_1:
      'If you have items in your order for which Group Buy was not achieved, we automatically move your items to the next available Group Buy date, so they can try to hit the minimum with other group of buyers. You will get notified by email with a new estimated collection date for your items provided.',
    p6_2:
      'If you do not wish to wait for your items any longer, you can cancel the items if the Group Buy minimum for them is not achieved and get refunded. If you’d like to make any amendments to your order, please contact your Collection Point Host via in-app chat. Your Host is able to fully manage your order, make cancelations, move your order to another collection date or to another collection point on your behalf.',
    p7_1:
      'In the rare event when a product that you’ve purchased was out of stock at the time of your purchase, your Collection Point Host will process a refund for you. If you have received a product from a supplier that is faulty (low quality, expired, etc.), please contact your Collection Point Host via in-app chat to raise a refund for you. Refunds may take 7-10 business days to appear in your bank account. For full information on cancelation and refunds policy, check our Refunds section.',
    p7_2: 'section.',
    p8_1:
      'Some products in your Cart might be marked as “unavailable” when the supplier of these products is not able to deliver to your chosen Collection Point. You may choose to select another Collection Point where the supplier currently delivers products, and checkout with a new Collection Point instead. Want a specific supplier to deliver to your favorite Collection Point? Talk to your Collection Point Host via our in-app chat! Your Host is the one connecting and communicating with suppliers, and can request for their deliveries!',
    p9_1:
      'Our platform only offers buyers a self-collection of orders from the Collection Point Host. We are passionate about sustainability and building communities. Your Collection Point Host receives all community orders for the neighborhood at once, allowing suppliers to redistribute their inventory more efficiently and to reduce carbon emissions to the atmosphere with bulk deliveries. Join us on a journey to sustainable grocery shopping!',
    privacy: 'Privacy',
    q1: 'How do I order?',
    q2: 'What is a Group Buy and how do I buy in a group?',
    q3: 'How do I join a Collection Point?',
    q4: 'How do I know when to collect my order? ',
    q5: 'Can I cancel my order?',
    q6: 'What if Group Buy for my order is not achieved?',
    q7: 'What if I receive an incomplete or faulty product? ',
    q8: 'Why some products in my Cart are “unavailable” for checkout?',
    q9: 'Do you provide home delivery? ',
    refunds: 'Refunds',
    related: 'Related Questions',
    title: 'FAQ',
    top_qustions: 'Top Questions',
    treedots: 'TreeDots'
  },
  favorite: 'Favourite',
  female: 'Female',
  file_saved: 'File Saved',
  find_out: 'Find out more here',
  find_products: 'Find Products',
  first_name: 'First Name',
  first_name_cannot_be_empty: 'First name cannot be empty',
  first_name_failed: 'First name update is failed!',
  first_name_must_be_at_least_2_characters: 'First name must be at least 2 characters',
  first_name_updated: 'First name updated successfully!',
  floor: 'Floor',
  floorNo: 'Floor No. (optional)',
  follow_us: 'Follow Us',
  for_next_delivery: 'for next day delivery.',
  forgot_password: 'Forgot Password',
  forgot_password_1: 'Forgot Password?',
  forgot_your_password: 'Forgot your password?',
  forward: 'Forward',
  fpx: 'FPX',
  friends_join: 'Friends Join',
  frozen_batang_steak: 'Frozen Batang Steak',
  fruits: 'Fruits',
  gender: 'Gender',
  gender_failed: 'Gender update is failed!',
  gender_updated: 'Gender updated successfully!',
  generic: 'generic',
  get_code: "Didn't get the code?",
  get_help: 'Get in touch for assistance!',
  got_a_voucher: 'Got a voucher?',
  grabpay: 'GrabPay',
  greeting: 'Good',
  group_buy: {
    how_group_buy_works: 'How Group Buy works',
    slide_1: 'Join a collection point nearby!',
    slide_2: 'Shop at your collection point with fellow buyers!',
    slide_3: 'Achieve Group Buy minimum to collect orders together!',
    slide_4: 'Chat with Host & your collection point community for updates, recipe sharing and more!'
  },
  group_buy_available: 'Group Buy Available',
  group_by_item: 'GROUP BY ITEM',
  group_description: 'Group Description',
  group_spend_total:
    'This supplier needs your group buy to spend a total of <strong>{currencyCode}%{minimum_order}</strong> to deliver your order to Collection Point.',
  gst_included: 'GST included, where applicable.',
  halal: 'Halal',
  halal_off: 'Enable if this company is a halal establishment and would not like to see non-halal products.',
  halal_on: 'Disable if this company is not a halal establishment. Non-halal products will be displayed.',
  halal_off_b2c: 'Enable if you would not like to see non-halal products.',
  halal_on_b2c: 'Disable if you would like to see non-halal products.',
  halal_product: 'Halal Product',
  halal_products: 'Halal Products',
  halal_type: 'Halal Type',
  handle_not_connect_mess_branch:
    ' %{customer_name} is not a main branch and cannot be connected to. <br><br> Please reach out to your office to request connection to the main company instead.',
  handle_not_connect_mess_inactive_customer:
    ' %{customer_name} has already been deactivated by %{tenant_name} and cannot be connected to. <br><br> Please reach out to your office for more information.',

  health: 'Health',
  hello: 'Hello',
  hello_new_string: 'Hello',
  help: 'Help',
  hi: 'Hi',
  history: 'History',
  hmm: 'Hmm...',
  home: 'Home',
  host: 'Host',
  host_account: 'Host Account',
  hot_deals: 'Hot Deals',
  hot_discounts: 'Hot Discounts',
  how_help: 'How can we help you?',
  image: 'Image',
  image_lager_than_5mb: 'That image is larger than 5 MB limit!',
  in: 'in',
  inactive_supplier:
    'Ordering for this supplier has not started yet. You can choose other Collection Point to buy from.',
  incorrect_card_number: 'Add new card is failed! Your card number is incorrect.',
  incorrect_email_or_password_entered: 'Incorrect email or password entered',
  incorrect_phone_number: 'Incorrect phone number entered',
  incorrect_role: 'You are not a customer or salesperson',
  incorrect_verification_code: 'Incorrect verification code entered',
  inquiries: 'Inquiries',
  instagram: 'Instagram',
  invalid_card_year: 'Add new card is failed! Your cards expiration year is invalid.',
  invalid_phone_number: 'Invalid Phone Number',
  invalid_price: 'Invalid price',
  invite_friends: 'Invite Friends',
  invite_friends_description:
    'Invite a friend to sign up and get $5 off next purchase for you and your friend',
  invite_friends_get_5: 'Invite Friends and get $5',
  ios: 'ios',
  is_the_company_you_want: 'Is the company you want to add listed above?',
  item_unavailable_after: 'Item is unavailable after',
  johor: 'Johor',
  join_now: 'JOIN NOW!',
  join_to_ordering: 'Join this tenant to start ordering',
  key_contact_label: 'Key Contact',
  operation_label: 'Operation',
  finance_label: 'Finance',
  key_contact: {
    company_with_key_contact: 'Companies with key contact',
    does_not_exits: 'does not exist',
    enter_first_name: 'Enter first name',
    enter_last_name: 'Enter last name',
    key_contact_information: 'Key Contact Information',
    select_position: 'Select position'
  },
  kg: 'KG',
  kitchen: 'Kitchen',
  klang_valley: 'Klang Valley',
  language: 'Language',
  language_preference: 'Language Preference',
  last_name: 'Last Name',
  last_name_cannot_be_empty: 'Last name cannot be empty',
  last_name_failed: 'Last name update is failed!',
  last_name_must_be_at_least_2_characters: 'Last name must be at least 2 characters',
  last_name_updated: 'Last name updated successfully!',
  last_order: 'Last order',
  last_quoted_price: 'Last Quoted Price',
  later: 'Later',
  learn_more: 'Learn More',
  leave: 'Leave',
  leave_channel_confirm_header: 'Leave this chat?',
  leave_channel_confirm_message:
    "You won't be able to see conversations in this chat. You can always go back by joining this collection point.",
  leave_channel_toast_message: 'You just left this channel',
  leave_community_channel_confirm_header: 'Leave Comunnity Chat?',
  leave_community_channel_confirm_message: 'Are you sure want to leave from this community chat?',
  leave_private_channel_confirm_header: 'Leave Private Chat?',
  leave_private_channel_confirm_message: 'Are you sure you want to leave from this private chat?',
  limit_price: 'Limit Price',
  linkedin: 'Linkedin',
  list_of_referrees_signed_up: 'List of referrees who have siged up to TreeDots',
  live_chat: 'Live Chat',
  live_chat_support_only_available_in_android_and_ios_app:
    'Live chat support only available in Android and iOS App',
  loading: 'Loading...',
  loading_infinity_scroll: 'Loading more data...',
  location_permission_text1: 'Hello there! Enter your postal code or allow location access to continue',
  location_permission_text2:
    'To enjoy our geolocation services, please allow location access for TreeDots GroupBuy.',
  location_permission_text3: 'You can also allow location access on your mobile by going to:',
  location_permission_text4: 'Settings > TreeDots GroupBuy > Location > Allow Location Access',
  location_permission_text5: 'Settings > Location > Location Permissions > TreeDots GroupBuy > Allow',
  log_in: 'Log In',
  login: 'Login',
  login_header_tagline: 'Welcome to TreeDots, where cheap and good quality food produce exists.',
  login_now: 'Login Now',
  login_sticky: 'Login to continue shopping!',
  login_with_app_instead:
    'This mobile number is registered with our Group Buy Application. Please try logging in to the Group Buy App instead.',
  login_with_email: 'Log In with Email',
  login_with_mobile: 'Log In with Mobile Number',
  logout: 'Logout',
  logOut: 'Logout',
  logout_confirm: 'Are you sure you want to logout?',
  looking_to_browse1: 'Looking to browse our  ',
  looking_to_browse2: 'Supplier',
  looking_to_browse3: '? Tap here!',
  main_company: 'Main Company',
  make_as_default_address: 'Make as default address',
  malaysia: 'Malaysia',
  male: 'Male',
  manage_account: 'Manage Account',
  mandatory_field: 'Fields are mandatory',
  map: {
    active_only: 'Active Only',
    cancel: 'Cancel',
    collection_point_is_inactive: 'Collection Point is inactive',
    collection_point_near_me: 'Collection Point Near Me',
    confirm: 'Confirm',
    continue: 'Continue',
    delelect: 'Deselect',
    dismiss: 'Dismiss',
    enter_an_address_to_view_collection_points: 'Enter an address to view collection points',
    enter_password: 'Enter password',
    enter_your_address: 'Enter your address',
    find_a_collection_point: 'Find a Collection Point',
    friends_only: 'Friends Only',
    get_your_pass: "Get your password from this Collection Point's host",
    halal: 'Halal',
    halal_only: 'Halal only',
    km: 'km',
    no_collection_point_nearby: 'No Collection Point nearby',
    not_connected_to_cp: 'You are not connected to any Collection Point!',
    not_open_for_orders_right_now: 'Not open for orders right now',
    pass_incorrect: 'Password is incorrect. Please try again.',
    popup_message: 'This Collection Point is not accepting orders right now. Continue?',
    prefer_buyers_know:
      'Host of this Collection Point prefers buyers they personally know. If you know the Host, add the password here or choose public collection point instead.',
    search_results: 'Search Results',
    search_this_area: 'Search this area',
    show_inactive: 'Show inactive',
    welcome_close: 'Close',
    welcome_join_now: 'Join now!',
    welcome_message: 'Join our Collection Point to receive updates on order and delivery!',
    welcome_title: 'Welcome to %{cp_name} Collection Points'
  },
  mark_all: 'Mark all as read',
  mark_all_as_read: 'mark all as read',
  mark_as_read: 'Mark as Read',
  mark_as_received: 'Mark As Received',
  mark_as_unread: 'Mark as Unread',
  markAsReadAll: 'Mark all as read',
  maximum_quantity_exceeded: 'Maximum quantity exceeded.',
  meals: 'Meals',
  meat: 'Meat',
  medium_color: 'medium',
  melaka: 'Melaka',
  member: 'Members',
  members: 'Members',
  menu: 'Menu',
  menu_items: {
    category: 'Category',
    home: 'Home',
    notifications: 'Notifications'
  },
  merchandise_subtotal: 'Merchandise Subtotal',
  merchant: 'Merchant',
  message_cart_empty: 'Your cart is empty.',
  message_forwaded: 'Message forwarded',
  message_join_to_tenant: 'You have requested to join this tenant',
  method_send_invoice: 'Please choose a method to send this invoice.',
  minimum_order_malaysia: 'Minimum Order Value is RM3.00',
  minimum_order_singapore: 'For non-ewallet payment, minimum order value is S$0.50',
  mission:
    'Envisioning a world without food loss, we aim to be the region’s catalyst to a modern-day food supply chain and an efficient distribution channel of food to make achieving sustainability easier and more accessible for all. We have a vision that food, that has been painstakingly reared and sowed, would not go to waste.',
  mobile_already_registered: 'This mobile number has already been registered',
  mobile_already_registered_please_login:
    'This mobile number has already been registered. Please log in with registered phone number or create using a new one.',
  mobile_not_registered: 'You have not registered with us yet, please sign up for an account to get started!',
  mobile_number: 'Mobile Number',
  mobile_phone: 'Mobile Phone',
  more_credit_term: 'More credit term options are available on your supplier dashboard',
  msg_delete: 'Message Deleted',
  mute: 'Mute',
  my_account: 'My Account',
  my_cards: 'My Cards',
  my_company: 'My Company',
  my_custom_class: 'my-custom-class',
  my_orders: 'My Orders',
  name: 'Name',
  name_az: 'Name A-Z',
  name_za: 'Name Z-A',
  nevermind_keep_my_account: 'Nevermind, keep my account',
  new_email: 'New Email',
  new_password: 'New Password',
  new_phone: 'New Phone',
  newcustomer: {
    active: 'Active',
    add_brand: 'Add Branch',
    add_debit: 'Add Debit/Credit Card',
    add_nets: 'Add NETS Bank Card',
    add_person_in_charge: 'Add Person In Charge',
    address: 'address',
    close_time: 'Close Time',
    company_name: 'company Name',
    days: 'Days',
    default_delivery_time: 'Default Delivery Time',
    delivery_details: 'Delivery Details',
    edit_business_information: 'Edit Business Information',
    edit_delivery_time: 'Edit Delivery Time',
    edit_image: 'Edit Image',
    edit_payment_card: 'Edit Payment Card',
    edit_person_in_charge: 'Edit Person In Charge',
    enter_delivery_interval: 'Delivery Instruction',
    language: 'Language',
    main_brand: 'Main Branch',
    notifications: 'Notifications will be sent in this language.',
    open_time: 'Open Time',
    payment_card_details: 'Payment Card Details',
    person_in_charge: 'Person In Charge',
    profile: 'profile',
    same_as_parent_company: 'Same as Parent Company',
    set_as_default: 'Set as Default',
    set_default_time: 'Set Default Time',
    set_delivery_days: 'Set Delivery Days',
    update_address: 'Update Address',
    update_person_in_charge: 'Update Person in Charge',
    upload_company_logo: 'Upload Company Logo',
    upload_image: 'Upload Images',
    set_payment_card_details: 'Set Payment Card Details',
    assign_as_soa_receiver: 'Assign as SOA receiver'
  },
  newest: 'Newest',
  next: 'Next',
  next_day: 'Next Day delivery is unavailable',
  no_delivery_date: 'Sorry! There are no delivery dates available for your order!',
  no: 'No',
  no_alert_confirm: 'No, create a new order.',
  yes_proceed_order: 'Yes, proceed order',
  no_cash_off:
    'Enable if you require this company to make payment either prior to or at the time of delivery in order to receive their items. The supplier will have the right to take back the items if this delivery condition is not fulfilled.',
  no_cash_on:
    'Disable if you do not require this company to make payment prior to or at the time of delivery to fulfil the order.',
  no_cc: 'No credit card added yet',
  no_chat_text1: 'Sorry, no result found.',
  no_chat_text2: 'We cannot find keyword you’re searching.',
  no_chat_text3: 'Please try another keyword.',
  no_continue_adding_new_company: 'No, continue adding new company',
  no_credit_card_selected: 'Please select a payment method',
  no_expiry_date: 'No expiry date',
  no_name: 'No name',
  no_notification: 'No Notification',
  no_notification_text1: 'Your notifications will show up here!',
  no_notification_text2: "We'll use this space to alert you on",
  no_notification_text3: 'orders and promos! :)',
  no_product_found: 'No Product Is Found',
  no_product_in_category: 'No products here yet,',
  no_quantity: 'The supplier does not have enough quantity for this item.',
  no_referred_text: 'No friends referred yet',
  no_results_found: 'No results found',
  no_search_result: 'We could not find any products that matched your search.',
  no_search_text1: 'We could not find any products that',
  no_search_text2: 'match your search!',
  no_subcategory1: 'This category has no items for now.',
  no_subcategory2: 'Come back next time!',
  no_supcategory: 'This supplier has no items for now.',
  no_supplier_text1:
    'We are committed to bring suppliers to your city onboard. We hope you’ll enjoy shopping products on our platform soon!',
  no_supplier_text2: 'Not sure what to do next?',
  no_supplier_text3: 'Please ',
  no_supplier_text4: 'contact us',
  no_transaction_made: 'No transaction made.',
  no_update_available: 'No update available',
  no_vouchers_text: 'No vouchers found',
  noData: 'No Data',
  non_halal_product: 'Non-Halal',
  non_pooling: 'Non-Pooling',
  notif_no_spree_open:
    'This Collection Point is not open for orders right now. Come back later or select another!',
  notification: 'Notification',
  notifications: 'Notifications',
  NPS_text: 'How likely would you recommend our app to your friends?',
  NPS_thank_you: 'Thank you for your feedback!',
  off: 'OFF',
  OK: 'OK',
  new_onboarding_1: 'High quality and low cost food ingredients for your business',
  new_onboarding_2: 'Manage multiple entities in one simple app',
  onboarding_button: 'Shop for Groceries',
  onboarding1_text: 'No. 1 Group Buy platform where friends and neighbours enjoy great discounts together!',
  onboarding1_title: 'High quality and low cost food ingredients for your business',
  onboarding2_text: 'We work with trusted suppliers who provide foods to markets and restaurants you enjoy.',
  onboarding2_title_1: 'High Quality Foods.',
  onboarding2_title_2: 'Manage multiple entities in one simple app',
  onboarding3_text: 'Shop for sustainable groceries and pick them up at your preferred Collection Point!',
  onboarding3_title: 'Discover',
  onboarding4_text: 'Chat with your collection point host and fellow buyers!',
  onboarding4_title: 'Join a growing community!',
  one_or_more_company:
    'One or more company in our database has the same postal code as the one you entered. If the company you are trying to add already exists, please connect to it instead',
  one_or_more_company1:
    'One or more company in our database has the same phone number as the one you entered. If the company you are trying to add already exists, please connect to it instead',
  open_alert_reject: 'Do you want to reject Pre-Order',
  optional: 'Optional',
  Optional: '(Optional)',
  or_sign_in_with: 'or sign in with',
  or_use: 'or use a more',
  order: {
    accept_rejection: 'Accept Rejection',
    accept_rejection_confirm_description:
      'Accepting the rejection means that you agree to end this dispute. You will not receive a refund.',
    accept_rejection_confirm_title: 'Are you sure to accept the rejection?',
    action_not_be_reversed: 'This action can not be reversed',
    allow_refund_default: 'Always refund to TreeDots Pay',
    amount_refunded: 'Amount Refunded:',
    appeal_description:
      'By rejecting the offer, this issue will now be escalated to the TreeDots Resolution Center. The review will take approximately 12-24 hrs. You will receive an update as soon as the review is finished.',
    appeal_reason_input_placeholder:
      'Please detail your reasons for this problem so we can investigate it quickly.',
    appeal_submitted: 'Appeal Submited',
    appeal_submitted_description:
      'This issue has now been escalated to the TreeDots Resolution Center. The review will take approximately 1 day. You will receive an update as soon as the review is finished.',
    appeal_to_treedots: 'Appeal to TreeDots',
    attachment: 'Attachment',
    back: 'BACK',
    browse: 'Browse',
    buy_again: 'Buy Again',
    cancel: 'Cancel',
    cancel_item: 'Cancel Item?',
    cancel_Item_modal: 'CANCEL ITEM',
    cancel_modal_text: 'Are you sure you want to cancel this item?',
    cancel_order_confirm: 'Are you sure to cancel this order?',
    cancel_refund_default: 'Not now',
    cancelled: 'Cancelled',
    processed: 'Processed',
    cashback: 'cashback',
    change_company: 'Change Company',
    chart_with_hub: 'Chat with Host',
    chat_not_ready: 'This community chat not ready yet!',
    collected: 'Collected',
    collection_date: 'Collection date:',
    collection_point: 'Collection Point',
    completed: 'Completed',
    date_of_request: 'Date of request',
    date_resolved: 'Date resolved',
    dispute: 'Dispute',
    dispute_attach_description:
      'Attach supporting evidence for your issue. You can attach photos (JPG, JPEG and PNG) or videos (MP4) up to 5 files. Maximum 10MB',
    dispute_attach_photo_video: 'Attach a photo or video',
    dispute_detail: 'Dispute Detail',
    dispute_history: 'Dispute History',
    dispute_id: 'Dispute ID',
    dispute_review_description:
      'This issue has now been escalated to the TreeDots Resolution Center. The review will take approximately 1 day. You will receive an update as soon as the review is finished.',
    draft: 'Draft',
    estimated: 'Estimated',
    estimated_collection_date: 'Estimated Collection Date',
    group_buy_progress: 'Group Buy progress',
    have_been_rewarded: "You've been rewarded with Cashback!",
    have_no_cancelled: 'You have no "Cancelled" items.',
    have_no_collected: 'You have no "Collected" items.',
    have_no_overview: 'You have no "Overview" items.',
    have_no_preparing: 'You have no "Preparing" items.',
    have_no_to_collect: 'You have no "To Collect" items.',
    manage_account: 'Manage Account',
    my_account: 'My Account',
    my_orders: 'My Orders',
    cancel_order_succeed: 'Cancelled successfully',
    need_help: 'Need help?',
    ongoing: 'Ongoing',
    opening_chat: 'Opening chat...',
    order_amount: 'Order Amount:',
    order_id: 'Order ID',
    order_No: 'Order No.',
    order_title: 'Orders',
    order_total: 'Order Total:',
    our_food: 'our foods to start ordering with us!',
    overview: 'Overview',
    package_no: 'Package No',
    polling_item: 'Pooling item',
    preparing: 'Preparing',
    qty: 'Qty:',
    reason_for_appeal: 'Reason for appeal',
    refund: 'Refund:',
    refund_modal_bold_text: 'Accounts > TreeDots Pay',
    refund_modal_header: 'Want to receive immediate refunds next time?',
    refund_modal_text:
      'Select TreeDots Pay e-wallet as primary option for all your refunds. You can always change it in ',
    refund_total: 'Refunded amount:',
    refunded: 'Refunded',
    rejoin_chat_message: "You've left this chat before. Want to join again?",
    rejoin_chat_no: 'NO, THANKS',
    rejoin_chat_title: 'Rejoin this chat?',
    rejoin_chat_yes: 'JOIN CHAT',
    resolution: 'Resolution',
    resolve_dispute: 'Resolve Issue',
    resolve_dispute_description: 'This issue will be automatically resolved within 24 hours.',
    submit_appeal: 'Submit Appeal',
    supplier_rejection_description1: 'The Supplier rejected the request to refund ',
    supplier_rejection_description2: ' for the product/s. Please check Dispute History to see details.',
    supplier_rejection_reason: 'Supplier Rejection Reason',
    text_item_required:
      'This item requires a minimum quantity to be confirmed for collection. Currently waiting for other buyers from your neighbourhood to pool this item! You can cancel your item if you do not wish to wait (you will not be charged for this item).',
    text_missing_items: 'Why do I have missing items?',
    text_pooling_items:
      'Pooling items require minimum quantity to be confirmed for collection. You’ve ordered a bit more than minimum for this pool. But do not worry! Your pending items can be confirmed in the next pool of buyers any time soon!',
    to_collect: 'To Collect',
    total_paid: 'Total Paid:',
    track_issue: 'Track Issue',
    view_detail: 'View Detail',
    view_history: 'View History',
    volume_deal: 'VOLUME DEAL',
    voucher_savings: 'Voucher savings:',
    want_to_cancel_issue: 'Are you sure you want to cancel issue?',
    you_will_receive: 'You will receive your refund within 7-10 business days.',
    weight_error_out_of_range:
      'Maximum order weight must be less than 100,000,000,000,000 KG. Please reduce order weight.'
  },
  order_added: 'Order added!',
  order_amount: 'Order Amount',
  order_created: 'We have received your order!',
  order_failed: 'Order Failed',
  order_now: 'Order Now',
  order_of_measure: 'Order Of Measure',
  order_received_date: 'Received Date',
  note_to_driver: 'Note to Driver',
  note_for_self_pickup: 'Note for Self Pick-up',
  order_submitted: 'Order Submitted',
  order_total: 'Order Total',
  orderB2b: {
    acceptingRemedy: 'Accepting the product without any remedy',
    accountNumber: 'Account Number',
    addPICMessage: 'Please add a PIC to the merchant account before submitting an order',
    attention: 'Attention',
    billTo: 'Bill To',
    companies: 'Companies',
    created: 'Created',
    deliverTo: 'Deliver To',
    delivery: 'Delivery',
    delivery_address: 'Delivery Address',
    deliveryInstruction: 'Delivery Instruction',
    deplayDelivery: 'Delay Delivery',
    detail: 'Detail',
    discount: 'Discount',
    dueDate: 'Due Date',
    filter: 'Filter',
    from: 'From',
    fulfillmentDateFrom: 'Fulfillment Date From',
    goodsSpoild: 'Goods Spoiled',
    grandTotal: 'Grand Total',
    gst: 'GST',
    invoice: 'Invoice',
    invoiceDate: 'Invoice Date',
    invoiceNumber: 'Invoice Number',
    item: 'Item',
    items: 'Items',
    missingItem: 'Missing Item',
    order: 'Order',
    orderItemDetail: 'Order Item Detail',
    orderNow: 'Order Now',
    orders: 'Orders',
    placeOrder: 'Place Order',
    poNumber: 'PO Number',
    preOrder: 'Pre-order',
    price: 'Price',
    purchase: 'Purchase',
    qty: 'Qty',
    remark: 'Remark',
    repeat: 'Repeat',
    repeatOrder: 'Repeat Order',
    reportProblem: 'Report a Problem',
    requestingCreditNote: 'Requesting for Credit Note',
    requestingExchange: 'Requesting for 1:1 exchange',
    requestingItem: 'Requesting to pick up wrong item',
    reset: 'Reset',
    selectDate: 'Select the end date must be greater than the start date',
    selectDeliveryDate: 'Select Delivery Date',
    selectProblem: 'Select the reason for your problem',
    selectRemedy: 'Selected remedy',
    sendInvoice: 'Send Invoice',
    separateInvoice: 'Separate Invoice',
    showInvoice: 'Show Invoice',
    downloadInvoice: 'Download Invoice',
    sku: 'SKU',
    status: 'Status',
    submitPreOrder: 'Submit Pre-order',
    subTotal: 'Sub Total',
    tax: 'Tax',
    taxInvoice: 'Tax Invoice',
    taxRegistrationNo: 'Tax Registration No.',
    to: 'To',
    fulfillmentDateTo: 'Fulfillment Date To',
    total: 'Total',
    totalPrice: 'Total Price',
    unitPrice: 'Unit Price',
    updateOrderSuccess: 'Updated successfully',
    viewDispute: 'View Dispute',
    viewDisputeStatus: 'View Dispute Status',
    weight: 'Weight',
    payment: 'Payment',
    paymentStatus: 'Payment Status',
    paymentSuccess: 'Payment Success',
    paymentSuccessMessage: 'Order has been successfully placed',
    paymentMethod: 'Payment Method',
    paymentTime: 'Payment Time',
    recipient: 'Recipient',
    orderNumber: 'Order Number',
    totalAmount: 'TOTAL',
    viewOrderDetails: 'View Order Details',
    backToHome: 'Back to Home',
    paymentFail: 'Payment Failed',
    paymentFailMessage: "Sorry! We have cancelled your order as we were couldn't receive payment for it",
    paymentPending: 'Payment Pending',
    paymentPendingMessage: 'Waiting for confirmation from payment partners to confirm your order!',
    paymentPendingHeadline: 'Complete your payment by:',
    paymentExpireIn: 'Expires in:',
    backToCart: 'Back To Cart',
    pendingPayment: 'PENDING PAYMENT',
    paymentInstruction: 'Payment Instructions',
    viewQRCode: 'View QR Code ',
    cancelPaymentTransaction: 'Cancel Payment & Order',
    paymentTransactionExpired: 'Payment has been expired!',
    paynowInstruction: 'Your PayNow QR code will be valid for 1 hour, kindly make payment before it expires!',
    cancelPaymentTitle: 'Cancel Payment and Order?',
    cancelPaymentHint: 'Your payment and order will be cancelled and you will not be charged.',
    cancelStayButton: 'Stay',
    cancelConfirmButton: 'Confirm',
    paymentStatusUpdated: 'Payment status has been updated!',
    paymentStatusUpdatedHint: 'Please close this page to continue',
    fluidWarning: 'Other payment methods are unavailable for Fluid-financed customers',
    unablePlaceBooking: 'Unable to Place Booking',
    pendingSupplierAcceptance: "Pending supplier's acceptance",
    deliveryFeeApplied:
      'Delivery fee {deliveryFee} will be applied as minimum order value {orderValue} is not fulfilled.',
    deliveryFeeUpdated: 'Delivery fee has been updated to {deliveryFee} as minimum order value is fulfilled.',
    deliveryFeeRemoved:
      'Minimum Order Value {minOrderValue} has been fulfilled. Delivery fee will be removed.'
  },
  ordering: 'Ordering & Collection',
  ordering_collection: {
    find_out_more: 'Find out more about Ordering and Collection here!',
    p1: 'When you shop with us, you become a part of a Group Buy.',
    p2: 'Browse wide selection of quality products and enjoy great deals together!',
    p3: 'Checkout groceries at your preferred Collection Point! ',
    p4: 'Achieve Group Buy minimum with fellow buyers and collect your order!',
    p5: 'Chat with your collection point Host if you have any questions about your order!',
    take_a_tour: 'Take a Tour',
    take_step: 'Take our step-by-step tour on How to Group Buy!'
  },
  orders: 'Orders',
  orders_temporarily_closed: 'Orders Temporarily Closed',
  originalPrice: 'Original Price',
  other: 'Other',
  other_methods: 'other methods',
  our_mission: 'Our Mission & Vision',
  our_research: {
    content_1: 'We found that more than 810,000 Tonnes of food was wasted in 2017. IN SINGAPORE ALONE.',
    content_2:
      'What makes it worse is that dumping so much food into landfills produces high quantities of methane gas, impacting our Planets health even more.',
    title: 'We’ve done our research'
  },
  our_solution: {
    content_1:
      'In mid 2019, we conceptualised the first version of our app. We created an online platform that allows suppliers to redistribute their excessive food stock through our order management and logistics services.',
    content_2:
      'By keeping our platform fees low we showed suppliers an alternative cost-efficient way to deal with their unsold inventories and to connect with their customers.',
    title: 'Our Solution'
  },
  our_team_will_reply_to_your_inquires_in_2_3_working_days:
    'Our team will reply to your inquires in 2-3 working days.',
  out_of_stock: 'Out of Stock',
  select_other_variant: 'Select Other Variations',
  select_variation: 'Select Variations',
  variation: 'Variation: ',
  available_variation: 'AVAILABLE VARIATIONS',
  similiar_product: 'Similar Product',
  overview: 'Overview',
  pack_of: 'PACK OF ',
  packaging_size: 'Packaging Size',
  paid: 'PAID',
  unpaid: 'UNPAID',
  parent_address: "Parent's addresses",
  password: 'Password',
  password_cannot_be_empty: 'Password cannot be empty',
  password_failed: 'Password update is failed!',
  password_must_be_at_least_6_characters: 'Password must be at least 6 characters',
  password_updated: 'Password updated successfully!',
  pay_now: 'Pay Now',
  payment: 'Payment',
  payment_method: 'Payment Method',
  payment_option: 'Payment Option',
  payment_option_page: {
    other_payment_method: 'Others Payment Method',
    treedots_balance_modal_page: {
      amount: 'AMOUNT',
      change_payment_method: 'Change Payment Method',
      oops: 'Oops, you don’t have enough balance',
      to_complete:
        'To complete your transaction, please top-up your TreeDots wallet or choose a different payment option',
      top_up_treedots: 'Top Up TreeDots Wallet'
    },
    treedots_pay: 'TreeDots Pay'
  },
  penang: 'Penang',
  per: 'Per',
  per_unit_price: 'Per Unit Price',
  perak: 'Perak',
  person: 'Person',
  person_type: 'Person Type',
  Person_type_cannot_be_empty: 'Person type cannot be empty',
  pet: 'Pet',
  phone: 'Phone',
  phone_alt_img: 'phone',
  phone_failed: 'Phone update is failed!',
  phone_logo_url: 'https://cdn2.iconfinder.com/data/icons/circle-icons-1/64/phone-256.png',
  phone_number: 'Phone Number',
  phone_number_cannot_be_empty: 'Phone number cannot be empty',
  phone_share_link: 'tel:+65 3138 5464',
  phone_updated: 'Phone updated successfully!',
  place_order: 'Place Order',
  please_check_your_email_password: 'Please check your email & password',
  please_enter_valid_mobile_number: 'Please enter valid mobile number.',
  please_enter_valid_username_password: 'Please enter a valid username and password',
  please_enter_verification_code:
    'Please enter the verification code that has been sent to your mobile number<br><strong>{countryCode} {number}</strong>',
  please_enter_email_verification_code:
    'Please enter the verification code that has been sent to your email<br><strong>{email}</strong>',
  please_provide_your_password: 'Please provide your password',
  please_provide_your_username: 'Please provide your username',
  please_select_language: 'Please select language.',
  please_try_again: 'Please try again',
  please_wait: 'Please wait',
  please_select_your_country: 'Please select your country',
  po_number: 'PO Number',
  pool_item: 'Pool Item',
  pool_together: 'Pool Together',
  pooling: 'Pooling',
  pooling_item: 'POOLING ITEM',
  position: 'Position',
  postal_code: 'Postal Code',
  postal_code_cannot_be_empty: 'Postal code cannot be empty',
  postal_code_not_found: 'Companies with postal code not found.',
  postal_code_placeholder: 'e.g 456123',
  price: 'Price',
  price_cannot_be_empty: 'Price cannot be empty',
  price_normal: 'price',
  price_per: 'Price /',
  price_update: 'Price Updated',
  price_updated: 'Price Updated',
  priceUpdatedSuccessfully: 'Price updated successfully',
  privacy: 'Privacy Policy',
  privacy_policy: {
    change_privacy: '7. Changes to Privacy Policy',
    children: '3. Children’s Privacy',
    collection: '2. Collection of Personal Data',
    cookies: '6. Cookies and Mobile Technology',
    effective: 'Effective as of 14 May 2020.',
    intro: '1. Introduction',
    p1:
      'This Privacy Policy applies to the products, services, website(s) provided, mobile application(s) provided and/or the business conducted, by TreeDots Enterprise (Private Limited), TreeDots International (Private Limited) (collectively and individually referred to as “TreeDots”, "we", "our", "us") and explains how we handle Personal Data and comply with the requirements of Singapore’s Personal Data Protection Act ("PDPA"). TreeDots takes seriously the security and privacy of the Personal Data of its customers and users of its website(s) (such as www.thetreedots.com) and of TreDots’s mobile application(s) (such website(s) and mobile application(s) may be collectively or individually referred to as the “Site”; our mobile application(s) may be referred to as the “App”).',
    p1_1:
      '1.1 By using our Services, Sites or Apps, you acknowledge and agree to the collection and use of your Personal Data in relation to this policy. We do not use or share your Personal Data by means other than described in this Privacy Policy.',
    p1_2:
      '2.1 This Privacy Policy will assist you in understanding how we collect, use, disclose and/or process the Personal Data you have provided to us or that we possess about you, as well as to assist you in making an informed decision before providing us with any of your Personal Data. The term “Personal Data” refers to information that is connected to you as an identifiable individual, defined under the PDPA to mean data, whether true or not, about an individual who can be identified from that data, or from that data and other information to which an organisation has or is likely to have access (“Personal Data”). This may include your name, address, telephone number, email address, date of birth, gender, credit card details, device ID, operating system or version, information about your purchases or preferences, and any other information collected by us where you are identified or identifiable.',
    p1_3: 'This Privacy Policy is subject to Singapore laws.',
    p2_1:
      '2.1 We may collect your Personal Data through various means, including but not limited to instances when you:',
    p2_1a:
      '• provide your Personal Data through our Site or App for the purpose of registering for and creating an account;',
    p2_1b: '• download or access the Site or App;',
    p2_1c: '• agree for the Site or App to access your Country/Region;',
    p2_1d: '• participate in a promotion or other App features;',
    p2_1e: '• process payment or credit transactions;',
    p2_1f:
      '• request for a product or services information or to receive any marketing, promotional or other types of communications;',
    p2_1g:
      '• request the provision of goods and/or services from us and/or we provide any other incidental business purposes related to or in connection with our Site or App • make purchases through our App;',
    p2_1h: '• make enquiries through our Customer Success department and/or;',
    p2_1i: '• interact with our sales staff or with us.',
    p2_2:
      '2.2 While using our Service, Site or App, or when requesting for our assistance, we may automatically collect or require you to provide us your personally identifiable information, that may include but not limited to:',
    p2_2a: '• name;',
    p2_2b: '• email address;',
    p2_2c: '• telephone number;',
    p2_2d: '• payment information;',
    p2_2e: '• location data;',
    p2_2f: '• media files;',
    p2_2g: '• communications history data;',
    p2_2h:
      '• usage and transaction data, including details about your searches, orders, the advertising and content you interact with on the Site or App, and other products and services related to you.',
    p2_2i:
      'You have no obligation to provide any of the Personal Data requested by us. However, depending on circumstances, we may not be able to provide you with certain products and services, or transact with you, in cases that depend on the collection, use or disclosure of your Personal Data.',
    p3_1:
      '3.1 We do not and do not intend to collect, use, share any of the Personal Data or transact through our platforms directly with anyone we know to be under the age of 18. If you are under the age of 18, you should use our Site, App or Service only with the involvement of a parent or guardian and should not submit any Personal Data to us. By providing any Personal Data to us, you declare that you are over the age of 18.',
    p4_1:
      '4.1 In conducting our business, TreeDots may/will need to disclose or share your Personal Data to our third party service providers, agents and/or our affiliates or related corporations, and/or other third parties for various Purposes, as such third parties, would be processing your Personal Data for these Purposes.',
    p4_2:
      '4.2 Our third party service providers may collect and process your Personal Data on our behalf or otherwise for one or more of the following Purposes, including but not limited to those which provide analytical, administrative, advertising, logistics and information technology services.',
    p5_1:
      '5.1 We will endeavour to take all reasonable steps to ensure your Personal Data is kept confidential and secure, and to take appropriate technical and organizational measures to prevent unlawful or accidental destruction, accidental loss, unauthorized disclosure or access or other unlawful forms of processing. User personal data is contained behind secured networks and is only accessible by a limited number of employees who have special access rights to such systems.',
    p5_2:
      '5.2 We implement reasonable security arrangements to ensure that your Personal Data is adequately protected and secured. However, we cannot assume responsibility for any unauthorized use of your Personal Data by third parties which are wholly attributable to factors beyond our control.',
    p6_1:
      '6.1 In addition to the above, we may use the following technologies (elaborated below) to automatically collect information about your activities on the App or Site, as the case may be:',
    p6_1a: '• Cookies • Web beacons • Analytical tags • Web server logs • Geo-location technologies',
    p6_2:
      '6.2 Cookies. For users of the Site, please note that TreeDots may deposit “cookies” in your computer or your mobile device in order to identify you. Cookies are small data text files that are sent from a server computer during a browsing session. Cookies are typically stored on the computer’s hard drive and are used by web-sites to simulate a continuous connection to the site. Security measures have been employed to prevent unauthorized access to visitor data. However, visitors acknowledge that TreeDots does not control the transfer of data over telecommunication facilities including the Internet. Therefore, to the extent permitted by law, TreeDots will not be responsible for any breach of security or the unauthorized disclosure or use of any such data on the Internet, through no fault of TreeDots. Not all cookies collect Personal Data and you may configure your browser to reject cookies. However, this may mean you may not be able to take full advantage of the services or features on the Site.',
    p6_3:
      '6.3 Web Beacons. Web beacons are small graphic images on a web page or in an email that can be used for such things as recording the pages and advertisements clicked on by users, or tracking the performance of email marketing campaigns.',
    p6_4:
      '6.4 Analytics Tags. We use analytical tags to analyse what our clients like to do and the effectiveness of our features and advertising. We may use information collected through analytical tags or tracked links in combination with your Personal Data.',
    p6_5:
      '6.5 Web Server Logs. Web server logs are records of activity created by the mobile device or computer that delivers the web pages you request to your browser. For example, a web server log may record the search term you entered or the link you clicked to bring you the webpage, information about your browser, such as your IP address and the cookies set on your browser by the server.',
    p6_6:
      '6.6 Geo-Location Technologies. Geo-location technology refers to technologies that permit us to determine your Country/Region. We may ask you to manually provide Country/Region information (like your postal code), or to enable your mobile device to send us your Country/Region information.',
    p7_1:
      '7.1 TreeDots reserves the right to change its Privacy Policy at any time and notify you about updates of the policy on our Platforms. To the fullest extent permissible under applicable law, your continued use of our Services, Sites or Apps, including placing of any orders, shall constitute your acknowledgment and acceptance of the changes made to this Privacy Policy.',
    p7_2:
      '7.2 This Policy applies to both buyers and sellers who use our Services, Sites or Apps except where expressly stated otherwise.',
    protecting: '5. Protecting your Personal Data',
    sharing: '4. Sharing and Disclosure of Personal Information',
    title: 'TreeDots Privacy Policy'
  },
  private: 'Private',
  processing: 'Processing',
  product: 'Product',
  product_availability: 'Product Availability',
  product_detail: {
    achieve_min: 'Achieve min. purchase value together',
    add_to_cart: 'Add to Cart',
    add_to_cart_failed: 'Failed Add to cart',
    add_to_cart_success: 'Added to cart successfully',
    almost_stock: 'Almost out of Stock!',
    buy_together: 'Buy together',
    buy_with_others: 'Buy with others',
    country_of_origin: 'COUNTRY OF ORIGIN',
    country_origin_info: 'It’s where the product imported from',
    discounted_price: 'Discounted Price',
    halal: 'HALAL',
    how_group_buy_works: 'How Group Buy Works',
    item_weight: 'ITEM WEIGHT',
    join_collection_point: 'Join Collection Point',
    packaging: 'PACKAGING',
    per_unit_price: 'PER UNIT PRICE',
    pooling: 'buyer needed',
    product_description: 'PRODUCT DESCRIPTION',
    quantity: 'QUANTITY',
    see_less: 'See Less',
    see_more: 'See More',
    total_price: 'TOTAL PRICE',
    uom_default: 'Unit',
    variation: 'VARIATION',
    select_variation: 'SELECT VARIATION',
    view_supplier: 'View Supplier',
    volume_deal: 'VOLUME DEAL',
    volume_deal_description:
      'Enjoy discounted prices and receive cashback for every item purchased when Collection Points hits volume!',
    volume_deal_details: 'Volume Deal details',
    similar_product: 'Similar Product',
    explore_other_product: 'Explore other Products',
    sharing_title: 'You may like %{product_name} from TreeDots for Merchants!',
    sharing_dialog_title: 'Share Product',
    sharing_text: 'Check out %{product_name} on TreeDots for Merchants now!\n',
    sold_out: 'Sold Out',
    buy_again: 'Buy Again',
    stock: 'Stock',
    out_of_stock: 'Out of Stock',
    merchant_product_discoverability: 'Merchant product discoverability',
    view_and_order: 'View and Order',
    view_and_order_hint: 'Merchant users can view the product and able to place an order by themselves.',
    view_only: 'View Only',
    view_only_hint:
      'Customers can only view the product. They are required to contact supplier to place an order.',
    can_not_view: 'Cannot View',
    can_not_view_hint: 'Merchant users cannot view and place order.'
  },
  product_found: 'Product Found',
  product_in_other_collection_point: 'Product is available at <b>%{collection_point_name}</b>',
  product_info: 'Product Info',
  product_information: 'PRODUCT INFORMATION',
  product_variation: 'PRODUCT VARIATION',
  product_weight: 'Product Weight',
  productInfo: 'Product Info',
  products: 'Products',
  products_found: 'Products Found',
  profile: 'Profile',
  profile_updated: 'Profile Updated',
  promos: 'Promos',
  prompt_select_collection_point: 'Please select your Collection Point!',
  providing: {
    content_1:
      'In 2020, amidst the Covid-19 pandemic, we improved our platform by connecting suppliers directly to end-consumers. We introduced a ',
    content_1a:
      'Now households and individual buyers can enjoy products from our suppliers at lower prices and partake active role in solving food waste issue with us.',
    content_2:
      'End-cosumers are buying products with their neighbours and friends together as a collective purchase. So suppliers do not need to break their bulk, could deliver orders to community areas at once while reducing carbon emission and allowing for even more effective means of redistribution.',
    groupbuy_app: 'Group Buy app. ',
    title: 'Providing for Households'
  },
  price_shown_tax_inclusive: 'Prices shown are tax inclusive',
  price_shown_before_tax: 'Prices shown are before tax',
  quantity: 'Quantity',
  Quantity: 'Quantity',
  quotation: 'Quotation',
  quotation_has_been_sent: 'Quotation has been sent!',
  quotation_price: 'Quotation Price',
  quotationApproved: 'Quotation has been approved',
  quotationPendingContent:
    'As your quoted price is below the limit set, the request has been forwarded to your supplier for further approval.',
  quotationPendingTitle: 'Your quotation is pending approval',
  quote_price: 'Quote Price',
  quote_price_great_direct_price_title: 'Quote above direct price?',
  quote_price_great_direct_price_body:
    'The customer will receive a quoted price that is higher than the product’s direct price. Are you sure?',
  quote_price_great_direct_price_agree: 'Yes, proceed',
  quoted: 'Quoted',
  quotedBy: 'Quoted By',
  re_enter_password: 'Re-enter password',
  read_more: 'Read more',
  received_your_problem:
    'We received your request. Please wait until one of our staff respond to your request.',
  recent_searches: 'Recent Searches',
  referral: 'Referral',
  referral_code_label: 'Referral (Optional)',
  referral_code_placeholder: 'Referral Code',
  refund_breakdown: 'Refund Breakdown',
  refund_description1: 'For items paid with vouchers, the refund amount excludes the voucher amount.',
  refund_description2: 'You can review the breakdown of refund here.',
  refund_tooltip1: 'Value of the voucher applied when user checked out',
  refund_tooltip2: 'Number of cancelled items on the order that we refunded',
  refund_tooltip3: 'Total amount refunded',
  refunded_amount: 'Refunded Amount',
  refunded_qty: 'Refunded QTY',
  refunds: 'Refunds',
  refunds_policy: {
    or: 'or ',
    p1:
      'TreeDots allows for cancelations and refunds if you’ve changed your mind after ordering a product. This only applies to products where Group Buy has not achieved its required minimum.',
    p2:
      'Products that qualify for cancelation must have “Preparing” status in your order. Products with the status “To Collect” can not be canceled due to change of mind reasons.',
    p3: 'All refunds due to change of mind are subject to the following terms and conditions:',
    p3_1: 'Item must have status “Preparing” in your order to qualify for cancelling and refund',
    p3_2: 'Items must be under Group Buy that has not achieved its required minimum',
    p3_3:
      'This refund policy does not apply to items, which have been stated as “To Collect” and had its Group Buy minimum achieved',
    p3_4: 'For items with vouchers, the refund amount excludes the voucher amount',
    p4a:
      'Refunds for canceled items are processed immediately after cancelation to the same payment method you’ve used to check out these items. ',
    p4b: 'You can find the refund information about your item under the “Canceled” section of your order.',
    p5:
      'You may request your collection point Host to make a cancellation on your behalf via in-app chat if you wish the Host to fully manage the cancellation for you. In the event of receiving faulty or incomplete products, please contact your collection point Host to raise a refund for you.',
    p6: 'If you have difficulties in canceling your order due to technical issues, please reach out to our ',
    refunds_take: 'Refunds take 7-10 business days to appear in your bank account. ',
    tech_call: 'Tech Support via call ',
    title: 'TreeDots Refund Policy',
    whatsapp: 'WhatsApp at +65 31384846.',
    whatsapp_my: 'WhatsApp at +65 89145600.',
    whatsapp_sg: 'WhatsApp at +65 31384846.'
  },
  register_today: 'Register Today!',
  registered_mobile_go_to_login:
    'Your phone number is already registered with our platform, Do you like to login with this mobile number?',
  reject: 'Reject',
  reject_pre_success: 'Reject Pre-order Success',
  rejectedBy: 'Rejected By',
  reminder_spree_close_date_and_collection_date:
    'Buy before <b>%{close_date}</b> to collect by <b>%{collection_date}!</b>',
  remove_card: 'Remove Card',
  remove_confirm: 'Are you sure you want to delete this card?',
  remove_mess: 'Remove this item?',
  remove_product_from_cart: 'Remove Item From Cart?',
  removedSuccessfully: 'Removed Successfully.',
  reply: 'Reply',
  report_your_problem: 'Report your Problem',
  request_again: 'Request again',
  request_approval: 'Request Approval',
  request_approve: 'Request Approval',
  request_at: 'Request At',
  request_quotation: 'Request Quotation',
  request_quotation_details: 'Request Quotation Details',
  request_quotation_failed: 'Request Quotation Failed',
  request_transaction: 'Request Transaction Details',
  requestAt: 'Request At',
  requested: 'Requested',
  requestQuotationDetails: 'Request Quotation Details',
  resend_code: 'Resend Code',
  resend_code_in: 'Resend Code in {duration}',
  reset_password: 'Reset Password',
  salesperson: 'Supplier Contact Person',
  sample: 'Sample',
  sampleProduct: 'Sample Product',
  save_address: 'Save Address',
  save_changes: 'Save changes',
  saved_successfully: 'Saved Successfully',
  seafood: 'Seafood',
  search: 'Search',
  search_collection_point_via_map: 'Search Collection Point via map',
  search_for: 'Search for',
  search_for_food: 'Search for food',
  search_in: 'Search in ',
  search_in_ang_mo_kio: 'Search in Ang Mo Kio',
  search_items: 'Search Items...',
  search_location: 'Enter Location Here',
  search_results_for: 'Search Results for',
  search_suppliers: 'Search suppliers...',
  search_term: 'search term.',
  see_all: 'See all',
  see_more: 'See More',
  select: 'Select',
  select_all: 'Select All',
  select_another_option: 'Select another option',
  select_collection_point: 'Select Collection Point',
  select_delivery_date: 'Set Delivery Date',
  select_pickup_date: 'Select Pick-up Date',
  select_delivery_time: 'Select Delivery Time',
  select_pickup_time: 'Select Pick-up Time',
  select_invoice: 'Select Invoice',
  select_payment_method: 'Select Payment Method',
  select_person: 'Select person',
  select_state: 'Select your preferred shopping area now to enjoy product selection there.',
  selectCompany: {
    branches: 'Branches',
    change: 'Change',
    change_company: 'Change Company',
    main_company: 'Main Company',
    products: 'Products',
    select_company: 'Select Company'
  },
  select_customer: 'Select Customer',
  selected_collection_point: 'Selected Collection Point',
  seller: 'Seller',
  selling_price: 'Selling Price',
  send: 'Send',
  send_document: 'Send a document',
  send_photo: 'Send a photo',
  send_verification_code: 'Send Verification Code',
  send_via_email: 'Send via Email',
  send_via_peppol: 'Send via Peppol Network',
  separate_invoice: 'Separate Invoice',
  set_birth_date: 'Set Birth date',
  set_default: 'Set Card as Default',
  set_main_card: 'Set Main Card',
  set_new_price: 'Set New Price',
  set_password: 'Set Password',
  set_password_for_sub_buyer: 'Set password for sub-buyer',
  set_time: 'Set Delivery Date and Time',
  set_time_self_pickup: 'Set Self Pick-up Date and Time',
  settings: 'Settings',
  share: 'Share',
  share_via: 'Share via',
  sign_in: 'Sign In',
  sign_in_failed: 'Seems it’s not the right password or email. Please try again or use Forgot Password.',
  sign_in_with_mobile_phone: 'Sign In With Mobile Phone',
  sign_in_with_password: 'Sign In With Password',
  sign_out: 'Sign Out',
  sign_out_confirmation: 'Sign Out Confirmation',
  delete_account_button: 'Delete My Account',
  delete_account_title: 'Are you sure?',
  delete_account_confirmation:
    'Your account will be deleted and you will lose all access to it. This action cannot be reversed.',
  delete_account_agree: 'Agree and delete my account',
  delete_account_go_back: 'Go Back',
  sign_up: 'Sign Up',
  singapore: 'Singapore',
  skip: 'Skip',
  sku: 'SKU',
  snacks: 'Snacks',
  social_shared_mess: 'Hi, TreeDots provides high quality and cheap inventories.\n\nDownload now at ',
  soldInUnits: 'Sold in units of {number}',
  out_of_max_quantity: 'Maximum quantity is {quantity}!',
  out_of_max_weight: 'Maximum weight is {weight} KG!',
  last_remaming_item: 'Last {remainingQuantity} remaining',
  last_remaming_weight_item: 'Last {remainingQuantity} KG remaining',
  some_items_not_favorite: 'Some items are not in Favourites.',
  sorry: 'Sorry !',
  spelling: 'spelling',
  stall: 'Stall',
  stallNo: 'Stall No. (optional)',
  staples: 'Staples',
  start_buying_choose_cp: 'To start buying in group, choose your collection point.',
  start_purchasing_today: 'Start Purchasing Today',
  started_with_avocados: {
    content_1:
      'One of the founders had been working in a commodities trading and shipping company in the past, and one fine day, due to unforseen circumstances, 24 Tonnes of Avocados was doomed to rot in a shipping port in China.',
    content_2:
      'The founders could not accept it. This sparked the idea of TreeDots. We wanted to be a company that saves high-quality fresh produce foods from being dumped to waste due to obsolete regulatory practices.',
    title: 'It all started with Avocados'
  },
  state: 'State',
  stay_tuned: 'Stay tuned!',
  stock_locations: 'stock locations',
  street: 'Street',
  street_number_cannot_be_empty: 'Street number cannot be empty',
  sub_total: 'Sub Total',
  submit: 'Submit',
  submit_report_mess: 'We received your request. Please wait until one of our staff respond to your request.',
  subtotal: 'Subtotal',
  successfully_connected_to_company: 'Successfully connected to company',
  success_replace_contact_person: 'Successfully replaced as sales contact person',
  sucess_msg_color: 'primary',
  supplier: 'Suppliers',
  support: 'Support',
  sweets: 'Sweets',
  switch_collection_point: 'Switch Collection Point',
  sync_state: {
    halal: 'Halal',
    non_halal: 'Non-Halal',
    not_applicable: 'Not Halal certified'
  },
  system_outage_head_text: 'We’re temporary down 😫',
  system_outage_sub_text: 'Our team is working to get the platform back, please give us some time to fix',
  tab_1: 'Tab 1',
  tabs: {
    account: 'Account',
    cart: 'Cart',
    categories: 'Categories',
    home: 'Home',
    orders: 'Orders'
  },
  take_photo: 'Take a photo',
  takeAction: 'Take Action',
  tap_here_to_view_description: 'Tap here to view description',
  tax: 'Tax',
  tax_amount: 'All prices include GST',
  tax_included: 'Tax Included',
  tech: 'Tech Support',
  telegr: 'Telegram',
  telegr_alt_img: 'telegram',
  telegr_link_to_share:
    'https://telegram.me/share/url?text=Hi%2C%20TreeDots%20provides%20high%20quality%20and%20cheap%20inventories.%0A%0ADownload%20now%20at%0Ahttps%3A%2F%2Fthetreedots.com%2Fdownload&amp;url=',
  telegr_logo_url: 'https://cdn3.iconfinder.com/data/icons/social-icons-33/512/Telegram-256.png',
  telegr_share_link: 'https://t.me/TreeDotsBot',
  tell_a_friend: 'Tell a Friend',
  terms: 'Terms of Use',
  terms_of_use: {
    collection: '5. Collections',
    effective: 'Effective as of 14 May 2020.',
    eligibility: '3. Eligibility',
    governing: '10. Governing Law and Jurisdiction',
    inability: '6. Inability to Collect',
    intelectual: '2. Intellectual Property',
    intro: '1. Introduction',
    intro_1:
      'These are the terms of use and sale (the “Terms”) for your use of the sites owned, controlled and managed by TreeDots Enterprise (Private Limited) and TreeDots International (Private Limited) (collectively “TreeDots”), including www.thetreedots.com and any TreeDots mobile application (the “Sites”). We may add additional sites from time to time as we expand our offerings and these Terms will govern those new sites when added.',
    intro_2:
      'These Terms are between you and TreeDots including without limitation users who are sellers, buyers, merchants or contributors of content, information and other materials. If you are entering into this agreement for and on behalf of a business entity, the term “you” in this agreement shall mean the business entity on whose behalf you are using the Sites, unless the context does not permit.',
    limitations: '9. Limitation of Liability',
    miscell: '11. Miscellaneous',
    p1_1:
      '1.1 You may be accessing our Sites from a computer or a mobile device (e.g. through any TreeDots mobile application). These Terms govern your use of our Sites and your conduct, regardless of the means of access.',
    p1_2:
      '1.2 We may engage with you on third party sites including social media webpages where we post content, such as http://www.facebook.com/thetreedots/ and others. TreeDots does not control those Social Sites and these Terms do not apply to companies that TreeDots does not own or control, or to the actions of people that TreeDots does not employ or manage. You should always check the terms of use posted on Social Sites.',
    p1_3:
      '1.3 We request that you read these Terms and our Privacy Policy carefully before accessing or using our Sites, or purchasing any products through the Sites. If you have any questions in relation to browsing and shopping online with us, please refer to our Frequently Asked Questions (“FAQ”), Ordering & Collection section or contact us.',
    p1_4:
      '1.4 By using the Sites, you agree to these Terms, and the provisions of our Privacy Policy, FAQ, Ordering & Collection and Refund Policies. TreeDots reserves the right to change or modify any of the terms and conditions contained in the Terms at any time, or upon notice as required by applicable laws. Any changes or modifications to the TreeDots Terms of Use will be effective upon posting of the revisions. The TreeDots Terms of Use that will apply to any orders placed through our Sites are the TreeDots Terms of Use that apply at the time you place the order. Your continued use of the Sites following posting of any changes or modifications constitutes your acceptance of such changes or modifications. If you do not agree with the TreeDots Terms of Use or the changes or modifications, you must immediately cease using the Sites.',
    p10_1:
      'You hereby agree that the laws of Singapore shall govern these Terms and that the courts of Singapore shall have non-exclusive jurisdiction over any dispute arising out of or in connection with these Terms.',
    p11_1:
      'You and TreeDots are independent contractors, and no agency, partnership, joint venture, employee-employer or franchiser-franchisee relationship is intended or created by these Terms.',
    p11_2:
      'If any provision of these Terms is held invalid or unenforceable, the legality, validity and enforceability of the remaining provisions shall not be affected or impaired.',
    p11_3:
      'Any failure by TreeDots to exercise a right or require performance of an obligation in these Terms shall not affect TreeDots’ ability to exercise such right or require such performance at any time thereafter nor shall the waiver of a breach of these Terms constitute a waiver of any subsequent breach.',
    p11_4:
      'You shall not assign any rights or delegate any obligations herein without our prior written consent and any attempted assignment or delegation in contravention of this provision shall be null and void and of no force or effect.',
    p11_5: 'These Terms do not give rights to any third parties who are not party to these Terms.',
    p2_1: '2.1 You may use the Sites only in the manner permitted by the TreeDots Terms of Use.',
    p2_2: '2.2. You shall not:',
    p2_2a:
      '2.2.1.Copy, decompile, reverse engineer, disassemble, attempt to derive the source code of, decrypt, interfere with, or disrupt the integrity or the performance of the Sites;',
    p2_2b:
      '2.2.2.Make or attempt to make any modification, adaptation, improvement, enhancement, translation or derivative work from the Sites;',
    p2_2c:
      ' 2.2.3.Violate any applicable laws, rules or regulations in connection with your access or use of the Sites;',
    p2_2d: '2.2.4.Use the Sites for any purpose for which it is not designed or intended;',
    p2_2e:
      '2.2.5.Use the Sites to send, post, or otherwise communicate any content which is offensive, indecent, threatening, abusive, insulting, harassing, defamatory, libellous, deceptive, fraudulent, tortious, obscene, profane, invasive of another person’s privacy, or racially, ethnically or otherwise objectionable;',
    p2_2f:
      '2.2.6.Use the Sites to send automated, unsolicited or unauthorised messages, advertising or promotional material or any junk mail, spam or chain letters.',
    p2_2g:
      '2.2.7.Use or attempt to use any scraper, robot, bot, spider, crawler or any other automated device or means to access, acquire, copy or monitor any portion of the Sites, or any data or content found or accessed through the Sites;',
    p2_2h: '2.2.8.Collect any information in respect of other users without their consent;',
    p2_2i: '2.2.9.Commit any act to avoid paying any applicable fees and/or charges;',
    p2_2j:
      '2.2.10. Attempt to and/or engage in any activity or act that is intended to abuse, abuses or inappropriately manipulates any promotion, campaign and/or discount codes offered through the Sites. Such act and activities include but are not limited to: creating fake or duplicate accounts;',
    p2_2k: 'generating fake orders;',
    p2_2l: '2.2.11. Authorize or encourage anyone to do any of the aforementioned;',
    p2_3:
      '2.3. All intellectual property rights (including, without limitation, copyright, trademarks, patents, design rights, and all other forms of intellectual property rights) in the Sites are owned by TreeDots. Furthermore, you acknowledge and agree that the source and object code of the Sites and the format, directories, queries, algorithms, structure and organization of the Sites are the intellectual property and proprietary and confidential information of TreeDots.',
    p2_4:
      '2.4. The Sites may utilise or include third party software that is subject to open source and third party licence terms (“Third Party Software”). You acknowledge and agree that your right to use such Third Party Software as a part of the Sites is subject to and governed by the terms and conditions of the open source and third party licenses applicable to such Third Party Software, including, without limitation, any applicable acknowledgements, licence terms and disclaimers contained therein. In the event of a conflict between these Terms and the terms of such open source or third party licences, the terms of the open source or third party licences shall prevail with regard to your use of the relevant Third Party Software. In no event shall the Sites or components thereof be deemed to be open source or publicly available software.',
    p3_1:
      '3.1 To be able to make purchases through our Sites, you will be required to provide us with your personal details. In particular, you will be required to provide us with your name, e-mail address, phone number and/or other contact details as indicated.',
    p3_2:
      '3.2 The information or personal details that you provide us shall be processed in accordance with our Privacy Policy in the context of your order. When you use our Sites, you agree to the processing of your information and details, and you confirm that all information and details provided by you are true and accurate.',
    p3_3:
      '3.3 When you place an order through our Sites, you confirm that you are over the age of 18 years and are legally eligible to enter into binding contracts. If you are under 18 years of age, you may only place an order with the consent of your parent or guardian. If you do not have consent from your parent or guardian, you must stop using the Sites.',
    p3_4:
      '3.4 Please note that if you do not provide us with all the information we need, you cannot place or process your order.',
    p4_1:
      '4.1 Product information and descriptions on the Sites have been provided to us by relevant Suppliers that sell on our platform. In such instances TreeDots did not independently verify product information claimed by Suppliers and can not be responsible for such claims.',
    p4_2:
      '4.2 While we try to provide an accurate description of the products, we do not warrant that the descriptions, information, pricing, promotions or other content available on the Sites is accurate, current or free from error. TreeDots may correct any errors, inaccuracies or omissions and to change or update information or cancel orders if any information on the Sites is inaccurate at any time without prior notice, including after you have submitted your order.',
    p4_3:
      '4.3 In the event that the product you receive is incorrect, incomplete or defective the TreeDots Refund Policy shall apply.',
    p4_4:
      '4.4 Prices shown on the Sites are in local currencies (Singapore Dollars (SGD) for Singapore region and Malasyian Ringgit (RM) for Malaysia region), are inclusive of GST and inclusive of Supplier delivery fee to the Collection Points.',
    p4_5:
      '4.5 The price of products will be as stipulated at all times on our Sites, except in the case of an obvious error. Although we make every effort to ensure that the prices featured on the Sites are correct, errors may occur. If we discover an error in the price of any of the products that you have ordered, we will notify you as soon as possible. If TreeDots is unable to contact you, your order may be cancelled and the amount paid by you will be refunded in full via the original payment method you used.',
    p4_6:
      '4.6 For information on the modes of payment available at TreeDots, please refer to the FAQ and Ordering & Collection sections.',
    p5_1:
      '5.1 The products displayed on the Sites are based on the country code you registered with. The products can be ordered within Singapore and Malaysia only and available for self-collection only. Please refer to our FAQ or Ordering & Collection sections if you need more information on the TreeDots collection terms.',
    p6_1:
      '6.1 Please refer to our FAQ and Refund sections for what options are available if you were unable to receive your package.',
    p7_1:
      '7.1 All returns must be done in accordance with the instructions set out in our FAQ and Refunds sections.',
    p8_1: '8.1 You must be at least 18 years of age to become a registered user.',
    p8_2:
      '8.2 You agree that you shall only submit or provide information which is accurate and true, and that you will keep the information provided up-to-date.',
    p8_3:
      '8.3 On registration for TreeDots account at our Site, you will need a password for your Account. You are responsible for ensuring your Account details including your password remain confidential, current, complete and accurate. You are responsible for all activities that occur under your Account and/or password (authorised or not) as if such activities were carried out by you. You shall notify TreeDots immediately if you become aware of or have reason for suspecting that the confidentiality of your Account details including your password has been compromised or if there has been any unauthorised use of your Account or if your Personal Data requires updating.',
    p8_4:
      '8.4 You may store your credit card information as part of your Account in order to enable faster checkout processes. The collection and handling of this information is governed by our Privacy Policy.',
    p8_5:
      '8.5 If you access and use the Sites on behalf of another person, company, organisation or legal entity (“Entity”), you represent that you have the authority to bind that Entity as the principal to the TreeDots Terms. To the extent that you do not have such authority, you agree to be bound to these Terms and you accept liability for harm caused by any use of the Sites.',
    p8_6:
      '8.6 TreeDots reserves the right to suspend or terminate your Account without prior notice for any reason including if the TreeDots Terms are violated.',
    p8_7: '8.7 You may deactivate your Account at any time through the Sites.',
    p8_8:
      '8.8 You acknowledge and agree that if you deactivate your Account or if TreeDots suspends or terminates your Account, you will lose any information associated with your Account. Upon termination, all rights granted to you in these Terms will immediately cease.',
    p9_1:
      'You acknowledge and agree that your only right with respect to any problems or dissatisfaction with the Sites is to request for termination of your account and/or discontinue any use of the services.',
    p9_2:
      'In no event shall TreeDots or its subsidiaries, affiliates, partners, suppliers or licensors be liable for any indirect, incidental, consequential, special or exemplary damages arising out of or in connection with your access or use of or inability to access or use the services or any third party content and services, or arising out of or in connection with your conduct or the conduct of other users in connection with the use of the Sites, whether or not such damages were foreseeable and whether or not TreeDots was advised of the possibility of such damages. Without limiting the generality of the foregoing, TreeDots’ aggregate liability to you (whether under contract, tort, statute or otherwise) shall not exceed the amount of fees received by TreeDots in respect of the services giving rise to such claims. The foregoing limitations will apply even if the above stated remedy fails of its essential purpose.',
    p9_3:
      'TreeDots does not warrant or represent that it assesses or monitors the suitability, legality, ability, movement or location of any users or third party providers and you expressly waive and release TreeDots from any and all liability, claims or damages arising from or in any way related to the users or third party providers.',
    p9_4:
      'Nothing in these Terms shall apply to or in any way limit or exclude TreeDots’ liability for: (a) death or personal injury caused by its negligence; (b) dishonesty, deceit or fraudulent misrepresentation; or (c) liability which may not otherwise be limited or excluded under any applicable laws and regulations.',
    product: '4. Product Information and Prices',
    refunds: '7. Refunds',
    title: 'TreeDots Terms of Use',
    your_account: '8. Your Account'
  },
  test_enterprise_branch: 'TEST-TreeDots Enterprise-Branch',
  text_copied_to_clipboard: 'Text copied to clipboard',
  text_remove_item_in_cart: 'This item will be removed from your cart',
  this_field_must_be_a_valid_email: 'This field must be a valid email',
  title_check_duplicate: 'Merge order?',
  duplicate_order: 'Duplicate Order',
  order_merged: 'Order merged - Your items have been added to order',
  new_order_placed: 'New order placed!',
  already_has: 'already has',
  an_order_with: 'an order with',
  for_delivery_on: 'for delivery on:',
  do_you_want_merge: 'Do you want to merge {orderCount} item(s) to the existing order #{orderNumber}?',
  do_you_want_proceed: 'Do you still want to proceed?',
  toast_color: 'primary',
  toast_color_error: 'danger',
  tomorrow: 'Tomorrow',
  tooltip_message_start: 'This product naturally',
  tooltip_message_second: 'varies in size. The final',
  tooltip_message_third: 'of',
  tooltip_message_four: 'this product may differ',
  tooltip_message_end: ' from what is listed.',
  top: 'Top',
  top_picks: 'Top Picks',
  topup: 'Top Up',
  total: 'Total',
  total_amount: 'Total Amount',
  total_bill: 'Total Bill',
  total_new_price: 'Total New Price',
  total_payment: 'Total Payment',
  total_price: 'Total Price',
  treedots_groupbuy: 'TreeDots Groupbuy',
  treedots_story: 'The TreeDots Story',
  treedotspay: 'TreeDots Pay',
  try_again: 'Try Again',
  twitter: 'Twitter',
  twitter_alt_img: 'twitter',
  twitter_link_to_share:
    'https://twitter.com/intent/tweet/?text=Hi%2C%20TreeDots%20provides%20high%20quality%20and%20cheap%20inventories.%0A%0ADownload%20now%20at%0Ahttps%3A%2F%2Fthetreedots.com%2Fdownload&amp;url=',
  twitter_logo_url: 'https://cdn4.iconfinder.com/data/icons/miu-flat-social/60/twitter-256.png',
  type_message: 'Type message here',
  un_paid: 'UNPAID',
  pending_payment: 'PENDING PAYMENT',
  paid_payment: 'FAILED',
  unable_to_connect: 'Unable to connect',
  unable_to_place_order: 'Unable to place order',
  unavailable: 'Unavailable',
  unavailable_product: 'Unavailable Products',
  understood_delete_account: 'Understood, delete my accout',
  undo: 'UNDO',
  per_unit: '[Unit]',
  unit: 'Unit',
  unitNo: 'Unit No. (optional)',
  unitPriceCannotBeEmpty: 'Unit price cannot be empty',
  unmute: 'Unmute',
  update: 'Update',
  update_address: 'Update Address',
  update_information: 'Update Information',
  update_item_success_mess: 'Update item status Success !',
  update_modal_header: 'New updates available!',
  update_modal_message: 'We improved our GroupBuy app. Please update to continue.',
  update_pre_order: 'Update Pre Order',
  update_profile: 'Update Profile',
  update_user_profile: 'Update User Profile',
  updateOrder: 'Update Order',
  confirm_order_update: 'Confirm Order Update',
  confirm_order_cancel: 'Confirm Order Cancellation',
  updateProfile: {
    birth_date_cannot_be_empty: 'Birth date cannot be empty',
    email_cannot_be_empty: 'Email cannot be empty',
    gender_cannot_be_empty: 'Gender cannot be empty',
    phone_number_must_be_at_least_5_characters: 'Phone number must be at least 5 characters'
  },
  use_this_postal_code: 'Use this Postal Code',
  username: 'Username',
  valid_till: 'Valid till',
  validation: {
    confirm_password: 'Password entered does not match',
    country_code: 'Select country code',
    email: 'Please enter a valid email address',
    first_name: 'First name must be more than 2 characters',
    password: 'Password must be more than 6 characters',
    phone: 'Please enter a valid phone number'
  },
  variations: 'VARIATIONS',
  vegetables: 'Vegetables',
  veggies: 'Veggies',
  verify_phone_number: 'Verify Phone Number',
  verify_your_number: 'Verify your number',
  verify_your_email: 'Verify your email',
  view: 'View',
  view_all_collection_points: 'View All Collection Points',
  view_order: 'View Orders',
  vision:
    'By 2025, TreeDots hopes to save 2 million tonnes of food from being wasted, thereby reducing carbon emissions by 18 million tonnes.',
  volume_deal: 'VOLUME DEAL',
  volume_deal_s: 'Volume Deal',
  voucher: 'Voucher',
  voucher_amount: 'Voucher Total',
  voucher_applied: 'Voucher Applied',
  voucher_deduction: 'Voucher Deduction',
  vouchers: 'Vouchers',
  vouchers_expiring_soon: 'vouchers expiring soon',
  wa: 'WhatsApp',
  wa_alt_img: 'whatsapp',
  wa_link_to_share:
    'whatsapp://send?text=Hi%2C%20TreeDots%20provides%20high%20quality%20and%20cheap%20inventories.%0A%0ADownload%20now%20at%0Ahttps%3A%2F%2Fthetreedots.com%2Fdownload',
  wa_logo_url: 'https://cdn4.iconfinder.com/data/icons/miu-flat-social/60/whatsapp-256.png',
  wa_share_link: 'https://wa.me/6531384846',
  waiting_for_approval: 'Waiting for approval from tenant',
  wallet: {
    add_bank_account: 'Add Bank Account',
    add_bank_fail: 'Add new bank account is failed!',
    add_bank_success: 'Add new bank account successfully!',
    add_new_bank_account: 'Add New Bank Account',
    amount_exceeds: 'This amount exceeds the maximum transferrable amount. Transferrable amount:',
    available_withdraw: 'Available Withdrawal Balance',
    balance: 'Balance',
    completed_withdraw: 'You’ve completed withdrawal!',
    info_withdraw: 'What does funds available mean?',
    is_required: 'is required!',
    item_refund: 'Item Refund',
    link_bank: 'Link your bank account',
    load_withdraw: 'Your withdrawal is being processed',
    main_info_withdraw:
      'Any balance received from donations are not allowed to be withdrawn. You can still use it as payment for any in-app purchase.',
    minimum_withdraw: 'Minimum withdraw value is $0.11',
    no_transaction: 'You don’t have any transaction list yet.',
    order_id: 'Order ID',
    order_payment: 'Order Payment',
    order_refund: 'Order Refund',
    primary_refunds: 'Set as primary refund destination',
    savings: 'SAVINGS:',
    select_bank: 'Select Bank',
    something_wrong:
      'Something went wrong... Seems there is an issue with this transaction. Please try again later.',
    to: 'to',
    top_fail_info:
      'Your Top Up transaction have failed, there seems to be an issue with payment method you used. Please try again or select another.',
    top_up: 'Top Up',
    top_up_page: {
      add_new_bank_account: 'Add New Bank Account',
      bank_transfer: 'Bank Transfer',
      bank_transfer_page: {
        add_bank_page: {
          account_name: 'Account Name',
          account_number: 'Account Number',
          account_type: 'Account Type',
          add_dbs_ocbc: 'Add DBS / OCBC account',
          bank_code: 'Bank Code',
          branch_code: 'Branch Code',
          current_account: 'Current Account',
          instruction_1: 'Log in to your banking app',
          instruction_2:
            'Select the Transfer to <strong>local bank account</strong> option, then choose <strong>Add Payee</strong>',
          instruction_3: 'Select the option to add payee using <strong>Bank Account</strong> details',
          instruction_4: 'Under Bank Name, select <strong>TREEDOTS ENTERPRISE PTE LTD</strong>',
          instruction_5: 'Enter your account number',
          instruction_5_1: '6591118888',
          instruction_5_2: '<strong>Copy</strong>',
          instruction_6: 'Be sure the account number is correct',
          instruction_7:
            'Enter your <strong>Payee name</strong> and <strong>Confirm</strong>, then select <strong>Transfer to Payee</strong>',
          instruction_8: 'Enter your top-up amount, add any comments to recipient and Confirm',
          instruction_9: 'TreeDots will receive your top-up within 30 seconds',
          saving_account: 'Saving Account',
          success: 'You can now use your Bank Account with TreeDots',
          top_up_via: 'Top Up via Bank Transfer',
          verified: 'Verified!',
          we_have_transferred: 'We have transferred some money to the above account. Amount received:'
        },
        amount: 'Amount',
        amount_tip: 'Make sure you send the exact amount indicated.',
        copy: 'Copy',
        instruction_1: 'Login to your ibanking page, and follow the information below to make a transfer.',
        instruction_2: 'Add a new recipient by following the information below.',
        instruction_3:
          'Once payment is successfully received, this invoice will be updated automatically within 5 mins. (You may need to refresh the page).',
        instruction_title: 'Transfer Instructions:',
        recipient_account_number: 'Recipient Account Number',
        recipient_account_number_placeholder: '6591118888',
        recipient_account_number_tip:
          'Tip: Check if the account number you input in your ibanking page is accurate.',
        recipient_bank: 'Recipient Bank',
        recipient_bank_placeholder: 'CIMB',
        recipient_name: 'Recipient Name',
        recipient_name_placeholder: 'TreeDots',
        top_up_via: 'Top Up via Bank Transfer'
      },
      card_page: {
        maximum_top_up: 'Maximum top up value is',
        minimum_top_up: 'Minimum top up value is',
        top_up_amount: 'Top up amount:',
        top_up_via: 'Top Up via Credit Card / Debit Card'
      },
      complete_page: {
        paid_total: 'Paid total ',
        the_top_up_amount: 'The top up amount will be updated in your wallet which might take a few minutes',
        title: 'You’ve completed top up!'
      },
      credit_card_debit_card: 'Credit Card/Debit Card',
      fpx: 'FPX',
      grabpay: 'GrabPay',
      grabpay_page: {
        top_up_via: 'Top Up GrabPay'
      },
      modal_page: {
        bank_transfer: 'Bank Transfer',
        fee: 'Fee',
        grabpay: 'GrabPay',
        netslink: 'NETSLink',
        no_fee: 'No Fee',
        ok: 'OK',
        payment_method: 'Payment Method',
        paynow: 'PayNow',
        please_make_sure:
          "Please make sure there's enough balance in your account or select another payment method.",
        review_and_confirm: 'Review and Confirm',
        top_up_amount: 'Top-up amount',
        top_up_failed: 'Top up failed!',
        top_up_successful: 'Top up successful!',
        total: 'Total',
        your_balance: 'Your balance will update in few minutes'
      },
      nets: 'NETSLink',
      netslink_page: {
        top_up_via: 'Top Up NETSLink'
      },
      paynow: 'PayNow',
      paynow_old_page: {
        add_your: 'Add Your PayNow Number',
        instruction_1: 'Log in to your banking app and select PayNow',
        instruction_2: 'Select <strong>Paynow to VPA</strong>',
        instruction_3: 'Enter your full VPA as shown below:',
        instruction_3_1: '+6591118888#TREEDOTS',
        instruction_3_2: '<strong>Copy</strong>',
        instruction_4: "Be sure the <strong>Recipient's Nickname</strong> is correct.",
        instruction_5: 'Enter the amount you wish to top up',
        instruction_6: 'Your top up should be reflected on this app within 30 seconds.',
        top_up_via: 'Top Up via PayNow'
      },
      paynow_page: {
        account_name: 'Account Name',
        account_name_placeholder: 'TreeDots',
        click_to_download: 'Click to Download QR code',
        copy: 'Copy',
        instruction_1_1: 'Make sure to only use the actual ',
        instruction_1_2: 'PayNow ',
        instruction_1_3: 'app to make the payment.',
        instruction_2_1: 'Make sure that you are making a payment to XFERS PTE. LTD. - CLIENT ACCOUNT',
        instruction_title: 'TRANSFER INSTRUCTION',
        the_qr_code:
          'The QR code is valid only for this particular transaction. on scanning the qr code, amount and reference number will be auto-filled.',
        top_up_via: 'Top Up via PayNow',
        uen: 'UEN (Unique Entity Number)',
        uen_placeholder: '4318123452R13'
      }
    },
    total_treedotspay: 'Total TreeDots Pay Balance: ',
    transaction_history: 'Transaction History',
    transfer_bank: 'Transfer to Bank',
    transfer_to: 'Transfer to:',
    tree_wallet: 'Tree-Wallet',
    turn_off_refunds_bottom:
      'Refunds will take 7-10 business days to appear in your bank. To receive refunds real-time, keep TreeDots Pay option as default.',
    turn_off_refunds_title: 'Turn off refunds to TreeDots Pay?',
    turn_off_refunds_top:
      'If turned off, you will receive all future refunds for cancelled orders to your bank account or payment method you used at the checkout.',
    turn_on_refunds:
      'Setting TreeDots Pay as the primary destination of refunds will allow you to receive all future refunds from cancelled orders real-time. You can change this setting anytime.',
    turn_on_refunds_title: 'Turn on refunds to TreeDots Pay?',
    understood: 'Understood',
    view_all: 'View All',
    volume_deal_refund: 'Volume Deal Refund',
    withdraw: 'Withdraw',
    withdraw_amount: 'Withdrawal amount:',
    withdraw_appear: 'The withdrawal amount will appear in your bank account which might take a few minutes',
    withdraw_fail_info:
      'Your Withdrawal transaction have failed, there seems to be an issue with bank account you are trying to use. Please try again or select another bank.',
    withdrawal: 'Withdrawal',
    withdrawal_failed: 'Withdrawal failed',
    your_wallet_is_protected: 'Your wallet is protected.'
  },
  vendor_connect: 'Vendor Connect!',
  we_are_sorry: "We're sorry!",
  weight: 'Weight',
  weight_size: 'weight, size',
  Weights: 'Weights',
  what_is_collection_point: 'What is Collection Point?',
  whatis_next: {
    content:
      '2021 is an exciting year for us! We are bringing more suppliers onboard and expanding our services to become a one-stop solution for supplier Order management, Inventory management, Third-party logistics, and Finance services.',
    title: 'What’s Next?'
  },
  which_state: 'Which state are you in?',
  yes: 'Yes',
  yes_confirm: 'Yes, Confirm',
  yes_reject: 'Yes, Reject',
  yes_connect_to_existing_company: 'Yes, connect to existing company',
  yes_replace_as_contact_person: 'Yes, replace as contact person',
  yes_leave_community_chat: 'YES, LEAVE',
  yesterday: 'Yesterday',
  your_information_is_protected: 'Your information is protected.',
  your_order: 'Your order',
  your_referral: 'Your referral',
  your_referral_code: 'Your referral code',
  your_referrees: 'Your referrees',
  your_reward: 'Your reward',
  unable_set_as_default: 'Unable set as default',
  for: 'for',
  this_will_be_added_to_all_user:
    'This will be added to all user’s companies and set as default payment method on this device',
  update_changes: 'Update Changes',
  this_branch: 'this branch',
  main_branch: 'Main Branch',
  card_has_been_deleted: 'Card has been deleted!',
  card_has_been_updated: 'Card has been updated!',
  competitor_price_feedback: 'competitor price feedback',
  submit_information_about_other_supplier: 'Submit information about other supplier',
  product_name: 'Product Name',
  market_price: 'Market Price',
  competitor_price: "Competitor's Price",
  input_competitor_name: "Input competitor's name",
  other_info_competitor: 'E.g Date purchased, promotional price',
  other_information: 'Other Information (Optional)',
  add_file: 'Add File',
  send_feedback: 'Send Feedback',
  open_document: 'Open Document',
  camera: 'Camera',
  feedback: 'Feedback sent!',
  product_price_update: 'Product Price Update',
  price_change_log: 'Price change log',
  all_is_un_selected: 'All is un-selected',
  price_is_quoted: 'Price is quoted',
  request_reject: 'Request Rejected',
  sort: 'Sort',
  reset_filter: 'Reset filter',
  apply_filter: 'Apply filter',
  most_popular: 'Most popular',
  product_name_a_z: 'Product Name A-Z',
  product_name_z_a: 'Product Name Z-A',
  most_purchased: 'Most purchased',
  date_purchased_new_to_old: 'Date purchased (New to Old)',
  date_purchased_old_to_new: 'Date purchased (Old to New)',
  find_company: 'Find Company',
  show_more: 'Show More',
  show_less: 'Show less',
  catch_weight: 'Catchweight',
  select_date: 'Select Date',
  select_preferred_language: 'Select Preferred Language',
  items_note: 'Items note',
  enter_description: 'Enter description (Optional)',
  po_number_optional: 'PO Number (Optional)',
  please_select_cuisine:
    'Please select cuisine that is related to your business. You can select more than one!',
  what_type_of_your_business: 'What type of your business’s cuisine?',
  selection: 'Selection',
  cuisine_preference: 'CUISINE PREFERENCES',
  edit_cusine: 'Edit Cuisine',
  add_cuisine: 'Add Cuisine',
  cuisine_preference_optional: 'Cuisine Preferences (Optional)',
  tell_us_about_your_business: 'Tell us about your business',
  cuisine: 'Cuisine',
  do_you_have_any_feedback: 'Do you have any feedback? (Optional)',
  input_feedback: 'Input Feedback ',
  have_not_set_pwd: 'Have not set password?',
  click_here: 'Click Here',
  dont_have_pwd: 'Don’t have password yet?',
  enter_your_mobile_number_to_set_pwd:
    'Enter your mobile number and we’ll send you a verification code to set a new password.',
  set_pwd: 'Set Password',
  phone_verified: 'Phone number successfully verified!',
  password_successfully_set: 'Password is successfully set!',
  all_companies: 'All Companies',
  all_consumers: 'All Consumers',
  consumers: 'Consumers',
  company_request: 'Company Request',
  company_in_review: 'Company in Review',
  you_have_company: 'You have company(s) that are pending your action',
  we_are_still_process: 'We are still in the process of reviewing your company',
  select_company_pic_name: 'Select Company or PIC Name',
  let_us_get_started: 'Let’s get started!',
  we_are_reviewing_company: 'We are reviewing your company',
  step1_add_company: 'Step 1: Add company',
  step2_we_will_review: 'Step 2: We will review and approve your company',
  step3_start_ordering: 'Step 3: Start Ordering!',
  add_new_company: 'Add New Company',
  search_company: 'Search Company or Account Number',
  search_company_admin: 'Search Company, PIC or Account Number',
  search_customer_admin: 'Search Customer, PIC or Account Number',
  thank_you_first_company: 'Thank you for adding your company!',
  we_received_registration:
    'We have received your registration and are in the process of reviewing your company.',
  get_started: 'Let’s get started!',
  other_info_error: 'Maximum number of 255 characters exceeded',
  file_upload_error: 'Maximum file size of 50 MB exceeded',
  type_your_company_cuisine: 'Type your company’s cuisine here',
  what_type_cuisine_company_sell: 'What type of cuisine does your company sell?',
  bargain_price: 'Bargain Price',
  special_price: 'special price',
  support_information: 'Supporting information/ Reason',
  input_proposed: 'Input proposed price or weekly order quantity',
  pending_approve: 'Pending Aproval',
  request_sent: 'Request Sent!',
  refresh: 'Refresh',
  only_allowed_symbol: 'Only allowed symbols are',
  you_have_overdue: 'You have ({totalInvoices}) unpaid invoices',
  kindly_make_payment: 'Kindly make payment now of ',
  my_invoices: 'My Invoices',
  customer_balance: 'Customer Balance',
  //   unpaid: 'Unpaid ({unpaidAmount})',
  unpaid_invoices: 'Unpaid Invoices ({unpaidInvoiceAmount})',
  overdue: 'Overdue',
  overdue_copy: 'Overdue',
  all_invoices: 'All Invoices',
  pay_invoices: 'Pay Invoices',
  total_invoice_amount: 'Total Invoice Amount',
  download_invoice: 'Download Invoice',
  invoice_date: 'Invoice Date: {invoiceDate} ',
  due: 'Due: {dueDate}',
  awaiting_payment: 'Awaiting Payment',
  pending_payment_approval: 'Pending Payment Approval',
  paid_copy: 'Paid',
  select_payment: 'Select Payment',
  use_customer_balance: 'Use Customer Balance',
  digital_payment_method: 'Digital Payment Method(s)',
  other_payment_method: 'Other Payment(s)',
  summary: 'Summary',
  pay: 'Pay',
  credit_note: 'Credit Note ({creditCode})',
  overpayment: 'Overpayment',
  bank_transfer_detail: 'Bank Transfer Details',
  transaction_date: 'Transaction Date',
  payment_value_date: 'Payment Value Date',
  the_date_that_effective: 'The date that the transaction becomes effective.',
  payment_proof: 'Payment Proof',
  reference_code: 'Reference Code (Optional)',
  input_reference_provided: 'Input the reference no. provided by your bank for this payment transaction.',
  notes: 'Notes (Optional)',
  payment_overdue_invoice: 'Payment for overdue invoice for {userName}',
  enter_desc_transaction: 'Enter a description about this transaction.',
  upload_file: 'Upload File',
  max_file_size: 'Max. file size is 50 mb',
  payment_summary: 'Payment Summary',
  payment_pending: 'Payment Pending',
  payment_success: 'Payment Success',
  payment_failed: 'Payment Failed',
  back_to_invoice: 'Back to Invoice List',
  back_to_home: 'Back to Home',
  pending: 'Pending',
  invoice_list: 'Invoice(s)',
  reference_number: 'Reference Number',
  payment_time: 'Payment Time',
  recipient: 'Recipient',
  payment_details: 'Payment Details',
  image_success_transaction: 'Image of your successful transaction',
  use_available_credits: 'Use Available Credits',
  remaining_credits: 'Remaining Credits:',
  request_detail_placeholder:
    'Ex: I want to apply all my available customer credit to Invoice A and none to Invoice B',
  request_detail_let_us_know:
    'Let us know here if you have any requests about the allocation of customer credit.',
  request_detail_optional: 'Request Details (Optional)',
  apply_available_credit: 'Apply Available Credits',
  input_amount: 'Input Amount',
  note_only: 'Notes',
  request_details_only: 'Request Details',
  payment_pending_info:
    'We are currently processing your payment and it will take some time for the updated payment status to be reflected on the system',
  grand_total: 'Grand Total',
  end_of_week: 'End of Week',
  available_credits: 'Available Credits',
  payment_creation_failed: 'Payment Creation Failed',
  missing_payment_proof: 'Please upload payment proof',
  applied: 'Applied',
  applied_credits: 'Applied Credits',
  bank_transfer: 'Bank Transfer',
  cannot_find_product: 'Oops, we can’t find this product.',
  select_another_one: 'Please select another one!',
  manage_companies: 'Manage Companies',
  manage_customers: 'Manage Customers',
  cheque: 'Cheque',
  cash: 'Cash',
  edit_payment_amount: 'Edit Payment Amount',
  amount_due: 'Amount Due',
  update_payment: 'Update Payment',
  update_amount: 'Update Amount',
  deposit_date: 'Cash Deposit Date',
  cheque_date: 'Cheque Deposit Date',
  transaction_reference_number: 'Transaction Reference No.',
  cash_detail: 'Cash Details',
  cheque_detail: 'Cheque Details',
  payment_amount_warning: 'Total Payment must be more than',
  not_sure_avail_credit: 'Not sure what is Available Credits?',
  not_sure_credit_desc:
    'If you have further questions about your available credits and how to use it, kindly contact us below',
  verify_mobile_num: 'Verify Mobile Number',
  sorry_went_wrong: 'Sorry, Something went wrong',
  we_detected_unusual_activity:
    'We have detected unusual activity on your IP address and have temporarily suspended this feature. Please try again in:',
  invalid_amount_negative: 'Invalid amount. Input amount cannot be negative',
  to: 'To',
  future_date_error: 'Please select a date that is on or before',
  past_date_error: 'Please select a date that is on or after',
  make_payment: 'Make Payment',
  cash_payment_collection: 'Cash Payment (Collection)',
  cash_payment_collection_detail: 'Cash Payment (Collection) Details',
  cash_collection_date: 'Cash Collection Date',
  cash_deposit: 'Cash Deposit',
  cash_deposit_detail: 'Cash Deposit Details',
  input_amount_error: 'Input amount cannot be greater than remaining credits',
  input_amount_error_2: 'Input amount cannot be greater than total amount due',
  image_error_size: 'Image size is too large. Please upload an image with size less than 5 MB',
  image_error_type: 'Only image files are allowed',
  amount_due_error: 'Amount due cannot be greater than or equal to',
  invoice_cn: {
    invoice: 'Invoice',
    invoice_s: 'Invoice(s)',
    credit_note: 'Credit Note',
    invoice_cn: 'Invoice(s) and Credit Note(s)',
    bill_to: 'BILL TO',
    grand_total: 'GRAND TOTAL',
    sub_total: 'Sub Total',
    tax: 'Tax',
    download: 'Download to view original invoice',
    download_pdf: 'Download  Invoice (pdf)',
    view_invoice_cn: 'View Invoice(s) and Credit Note(s)',
    invoices: 'INVOICES',
    cn: 'CREDIT NOTE',
    all_file: 'Download  All Files',
    cn_amount: 'CREDIT NOTE AMOUNT',
    download_cn: 'Download to view original Credit Note',
    download_cn_pdf: 'Download  Credit Note (pdf)',
    download_all: 'Download  All Files',
    view: 'View',
    download_file: 'Successfully downloaded file',
    download_files: 'Successfully downloaded files',
    download_file_fail: 'File failed to download',
    download_files_fail: 'Files failed to download',
    view_document: 'View Document',
    preview: 'Preview',
    share_invoice: 'Share Invoice',
    share_credit_note: 'Share Credit Note'
  },
  search_company_not_found: 'Oops, company not found',
  try_different_name: 'Try different Company Name or Account Number',
  try_different_name_admin: 'Try different Company Name, PIC or Account Number',
  sorry_could_not_find: 'Sorry, couldn’t find',
  sorry_not_any_products: 'Sorry, we couldn’t find any products!',
  sorry_not_found: 'Sorry, we couldn’t find "{search_keyword}"',
  try_different_keyword: 'Please try different keywords or reset filter',
  today: 'Today',
  companies: 'Companies',
  select_other_company: 'Select Other Company',
  selected_count: '{selected_count} Selected',
  customer_instruction: 'Customer Delivery Instruction',
  delivery_instruction_hint: 'Ex: Input standing instructions for all orders from this customer',
  ex_delivery_instruction: 'Ex: "Input standing instructions for all orders from this customer"',
  no_invoices: 'No invoices yet!',
  browse_your_business: 'Start browsing for your business',
  browse_product: 'Browse Product',
  default_billing_address: 'Default Billing Address',
  set_as_default_address: 'Set as default billing address',
  select_address: 'Select Address',
  update_app: 'Updating App Version',
  better_experience: 'We’re updating the app for your better experience',
  wait_update: 'Please wait for this update to be completed.',
  you_have_no_company: 'You have no company.',
  register_your_company: 'Register your company now to purchase products',
  previous: 'Previous',
  updated: 'Updated',
  removed: 'REMOVED',
  new_request: 'New Request',
  dismiss: 'Dismiss',
  view_details: 'View Details',
  master_card_debit: 'Master Card (debit)',
  visa_card_debit: 'Visa Card (debit)',
  nets_payment_error: 'NETs payment error',
  on_registration: 'On Registration',
  alert_error_header: 'Error',
  select_business_profile: 'Select Business Profile',
  connection_issue_message:
    'Unable to load - Please make sure you are connected to the Internet and try again',
  unable_load: 'Unable to load',
  please_make_sure_connected: 'Please make sure you are connected to the Internet and try again',
  load_failed: 'Load failed',
  failed_to_fetch: 'Failed to fetch',
  page_taking_longer:
    'This page is taking a longer time than it should to load. You might want to check your internet connection',
  pull_to_refresh: 'Pull down to refresh',
  black_listed: 'Blacklisted',
  search_all_products: 'Search All Products',
  product_category: 'Product Category',
  relevant: 'Relevant',
  filter: 'Filter',
  favorites: 'Favorites',
  result_for: 'Result for <strong>"<i>{search_key}</i>"</strong>',
  result_from: 'Results from <strong>Product Category: {category_name}</strong> ',
  product_have_purchased: 'Products you’ve purchased',
  more_products: 'More Products',
  with_price: 'With price',
  in_stock: 'In stock',
  apply_search_filter: 'Apply Filter',
  reset_search_filter: 'Reset Filter',
  no_preview_docs: 'No Preview Available, it might be because it is a batch documents.',
  cancel_payment: 'Cancel Payment',
  cancel_payment_desc: 'Your payment will be cancelled',
  pending_verification: 'Pending Verification',
  pending_validation: 'Pending Validation',
  view_invoice: 'View Invoice(s)',
  view_payment_history: 'View Payment History',
  payment_history: 'Payment History',
  total_paid_amount: 'Total Paid Amount',
  partially_paid: 'Partially Paid',
  order_processed: '{isOrderBooking} Processed',
  order_processing: '{isOrderBooking} Placed',
  order_cancel_by_supplier: '{isOrderBooking} Cancelled by Supplier',
  order_cancelled: '{isOrderBooking} Cancelled',
  delivery_started: 'Delivery Started',
  delivered: 'Delivered',
  failed_delivery: 'Failed Delivery',
  order_completed: 'Order Completed',
  receive_your_order: 'We have received your {isOrderBooking}',
  is_preparing_order: 'is preparing your {isOrderBooking}',
  cancelled_by_supplier: 'Your {isOrderBooking} has been cancelled by {supplierName}',
  has_been_cancelled: 'Your {isOrderBooking} has been cancelled.',
  has_pickup_order: 'Our logistic partner has picked up your {isOrderBooking} item(s)',
  delivered_success: 'Your {isOrderBooking} item(s) were successfully delivered!',
  order_is_on_hold: 'Your order is on hold',
  on_hold: 'On Hold',
  delivery_failed: 'Sorry, our delivery attempt has been unsuccessful',
  order_has_been_completed: 'Your order has been completed!',
  track_order: 'Track Order',
  catch_weight_cap: 'CATCHWEIGHT',
  cap_grand_total: 'GRAND TOTAL',
  delivery_information: 'DELIVERY INFORMATION',
  billing_information: 'BILLING INFORMATION',
  bill_name_and_address: 'Billing Name & Address',
  order_delivery_instruction: 'Delivery Instruction',
  view_driver_location: 'View Driver Location on Map',
  not_found_order: 'Sorry, we couldn’t  find the order you were looking for!',
  view_all_order: 'View All Orders',
  cancellation_reason: 'Cancellation Reason',
  failure_reason: 'Failure Reason',
  note: 'Note',
  track_driver_location: 'View Driver Location',
  view_image: 'View Image',
  payment_date: 'Payment Date',
  invoice_total: 'Invoice Total',
  items: 'Items',
  payment_term: 'Payment Term:',
  paid_amount: 'Paid Amount',
  invoices_payment_history: 'Invoices and Payment History',
  not_found_payment_history_title: 'You don’t have Payment History',
  not_found_payment_history_desc: 'Go to Homepage to browse some products!',
  go_homepage: 'Go to Homepage',
  invoices_amount: 'Invoice(s) Amount',
  cancel_submitted_payment: 'Cancel Submitted Payment?',
  cancel_submitted_payment_desc:
    'Your submitted payment is still pending approval. Once you cancel it, this action cannot be undone',
  confirm_cancel_payment: 'Yes, cancel my payment',
  cancel_payment_success: 'Your payment has been cancelled',
  view_all: 'View All',
  you_have_overdue_invoices: 'You have ({totalInvoices}) invoices that are due.',
  due_today: 'Due Today',
  unable_to_cancel: 'Unable to Cancel',
  payment_status_changed: 'payment status has changed!',
  others: 'Others',
  cancelled_by: 'Cancelled by',
  create_as_booking: 'Create as Booking',
  booking: 'Booking',
  place_booking: 'Place Booking',
  track_booking: 'Track Booking',
  booking_lowcase: 'booking',
  order_lowcase: 'order',
  booking_confirmed: 'Booking Confirmed',
  booking_has_been_confirmed: 'Your booking has been confirmed!',
  create_booking: 'Create Booking',
  unable_create_booking_title: 'Unable to create booking',
  unable_create_booking_body: 'Sorry, a booking can’t be created for item(s) without inventory',
  unable_create_booking_message:
    'Please remove them or contact your adiminstrator for more information before proceeding',
  booking_added: 'Booking added!',
  items_not_available: 'Some item(s) are not available',
  order_is_pending_acceptance: 'Order is pending acceptance by the supplier.',
  contact_supplier: 'Contact Supplier',
  credit_block: 'Credit Block',
  rejected: 'Rejected',
  contact_to_purchase: 'Contact to Purchase',
  i_would_like_to_purchase: 'Hi! I would like to purchase {sku_name} for {company_name}:',
  top_up_credits: 'Top Up Credits',
  add_cust_credit_to: 'Add customer credit to:',
  back_to_account: 'Back to Account Page',
  continue: 'Continue',
  some_item_no_available: 'Sorry, some items are out of stock or no longer available',
  return_to_cart: 'Return to Cart',
  no_longer_available: 'No longer available',
  sign_up_instead: 'sign up instead',
  view_similar_products: 'View Similar Products',
  order_has_been_amended: 'Your order has been amended',
  you_have_placed_order: 'You have placed your {isOrderBooking}',
  updated_by: 'updated by ',
  update_type: {
    system: 'system',
    you: 'you',
    supplier: 'Supplier',
    merchants: 'Merchants',
    sales: 'Salesperson',
    admin: 'Admin'
  },
  update_by_user: '{userName} ({userType}, User ID: {userId})',
  order_update_detail: 'Order Update Detail',
  track_order_update_status: {
    new: 'New',
    updated: 'Updated',
    replaced: 'Replaced',
    removed: 'Removed'
  },
  contact_supplier_to_purchase: 'Contact your supplier to purchase this product!',
  why_my_order_is_on_hold: 'Why is my order on hold?',
  tap_to_view_info: 'Tap to view info',
  order_on_hold: 'Order On Hold',
  credit_limit_exceeded: 'Credit limit exceeded',
  please_make_payment_or_contact_supplier:
    'Please make payment or contact your supplier for more information!',
  please_make_payment_or_contact_admin:
    'Please make payment or contact your administrator for more information!',
  max: 'Max',
  order_no_longer_blocked: 'Order is no longer blocked, please refresh the page to view the latest status.',
  i_have_question_about_order: 'Hi! I have a question about my order #{orderId}',
  contact_your_supplier: 'Contact your supplier!',
  secure_checkout: 'Secure Checkout',
  you_will_place_this_order: 'You will place this order, continue',
  we_have_sent_you_message: 'We have sent you a text message with a code to your registered mobile number',
  purchase_auth: 'Purchase Authentication',
  enter_your_code: 'Enter your code',
  code_has_been_sent: 'Code has been sent!',
  your_order_will_be_cancelled: 'Your order will be cancelled and you will not be charged',
  transaction_has_been_cancelled: 'Transaction has been cancelled!',
  stay: 'Stay',
  order_processing_status: '{isOrderBooking} Processing',
  booking_completed_status: 'Booking Completed',
  unable_to_authorise: 'Unable to Authorise',
  new_request_info1: 'from',
  new_request_info2: 'would like to assign you as',
  new_request_info3: 'person for',
  orders_and_invoices: 'Orders & Invoices',
  orders_and_invoices_notification: 'Orders & Invoices Notification',
  products_updates: 'Product Updates',
  products_updates_notification: 'Product Updates Notification',
  quotation_notification: 'Quotation Notification',
  all_notifications: 'All Notifications',
  approved: 'Approved',
  few_seconds_ago: 'few seconds ago',
  ago: 'ago',
  day: 'day',
  hour: 'hour',
  min: 'min',
  new_request_info:
    '{applicant} from {supplierName} would like to assign you as {position} person for {customerName}',
  new_request_buyer: '{applicant} would like to assign you as {position} person for {customerName}',
  new_assigned_company:
    'There are {connectedPersons} companies that assigned you as key contact. Please review and acccept or reject.',
  back_to_notifications: 'Back to Notifications',
  nothing_here_yet: 'Nothing here yet! check back later',
  no_new_unread_notif: 'No new unread notification',
  products_result: 'result',
  not_provided_address: 'Addresses not provided',
  your_company: 'Your Company',
  more: 'More',
  unable_to_authorize: 'Unable to Authorise',
  product_stock_update_label: 'Notify me of product stock updates',
  product_stock_update_desc:
    "You'll be the first to know when there are changes to this product's availability! (e.g, back in stock, limited stock or out of stock)",
  notif_alert_label: '"TreeDots for Merchants" Would Like to Send You Notification',
  notif_alert_desc:
    'Notifications may include alerts, sounds and icon badges. These can be configured in Settings.',
  allow: 'Allow',
  not_allow: "Don't Allow",
  info: 'Info',
  you_will_be_notified: 'You will be notified of product stock updates',
  you_will_not_be_notified: 'You will not be notified of product stock updates',
  company_updates: 'Company Updates',
  glazing_desc: 'Glazing refers to the protective layer of water added to the surface of frozen seafood',
  payment_status: {
    paid: 'Paid',
    rejected: 'Rejected',
    cancelled: 'Cancelled',
    pending_validation: 'Pending Validation',
    pending_approval: 'Pending Approval'
  },
  no_guaranted_delivery: 'Delivery not guaranteed',
  past_cut_off_time: 'Past cut-off time',
  no_guaranted_message:
    'Your selected delivery timing has passed its cut-off time. Have you checked if the order can still be fulfilled',
  change_delivery_time: 'Change Delivery Time',
  create_order: 'Create Order',
  sample_product: '[FREE SAMPLE]',
  set_sample_product: 'Set as Free Sample',
  unable_booking_sample: 'Unable to Create as Bookings for Sample Item.',
  photo: 'Photo',
  delete_this_item: 'Delete this item?',
  yes_delete: 'Yes, delete',
  free_sample: 'Free Sample',
  order_status: 'Order Status',
  fulfillment_status: 'Fulfillment Status',
  delivery_status: 'Delivery Status',
  delivery_successful: 'Delivery Successful',
  order_by_weight: 'Order by weight',
  unit_price: 'Unit Price',
  qoted_by: 'Quote by',
  high_quality: 'High quality',
  low_cost_food: 'low cost food ingredients',
  for_your_business: 'for your business',
  manage: 'Manage',
  multiple_entities: 'multiple entities',
  in_one_simple: 'in one simple app',
  missing_customers_data_label: "Oops, we encountered an error while loading your company's data",
  missing_customers_data_desc: 'Kindly re-select your company to proceed',
  missing_customer_data_label: 'Oops, there is some unloaded data',
  missing_customer_data_desc: 'Tap "Refresh" app to continue',
  confirm_create_duplicate_customer_title: 'Are you sure want to Create a Duplicate Customer?',
  captcha_invalid: 'Invalid Captcha',
  manage_cards: 'Manage Cards',
  b2c_customer: 'B2C',
  order_create_b2c_permission_not_authorized: 'Not authorized to place order for B2C customer',
  stores: 'Stores',
  no_connected_tenant_err: 'You are not connected to any supplier. Please contact sales or tech support.',
  spend_more_to_free_delivery: 'Spend {orderAmount} more for free delivery',
  delivery_fees: 'Delivery Fees',
  free: 'Free',
  waived: 'Waived',
  delivery_fee_updated: 'Delivery Fee Updated',
  before_tax: 'Before Tax',
  after_tax: 'After Tax',
  connecting_to: 'Connecting to',
  connect_to_company: 'Connect to Company with PIN Code',
  input_provided_code_to_connect: 'Input the provided 6 digit code to connect to the company',
  enter_six_digits_pin_placeholder: 'Enter 6 digits PIN',
  do_you_accept_connection: 'Do you accept the connection?',
  you: 'You',
  user_connected: 'User connected',
  user_inactive: 'User inactive',
  no_user_connected: 'No user connected',
  invitation_pin: 'Invitation PIN',
  view_pin: 'View PIN',
  copy_to_clipboard: 'Copy PIN to Clipboard',
  valid_until: 'Valid until',
  expired: 'Expired',
  send_new_pin: 'Send New PIN',
  send_new_pin_message:
    'Are you sure you want to invite {user_name} to the company? A 6 digit PIN code will be sent to {phone_number} via SMS.',
  onboarding_pin_input_prompt_message:
    'Input the provided 6 digit PIN code to connect to an existing company. Or skip to create a new company which will be subjected a review and approval.',
  six_digits_pin_code_will_be_sent: 'A 6 digit PIN code will be sent to {phone} via SMS.',
  add_pic_with_non_existing_user_message:
    '{user_name} will be required to sign up before using the 6 digit PIN code to connect to the company',
  add_pic_with_existing_user_message:
    '{user_name} can use the provided 6 digit PIN code to connect to the company',
  pic_pin_input_limit_message:
    '{retry_limit} incorrect attempts to verify Customer Connection PIN Code. Please try again in 1 minute.',
  please_wait_for: 'Please wait for',
  phone_number_already_used_by_another_pic: 'Phone number already used by another PIC',
  not_eligible_for_self_pickup: 'Not eligible for self pick-up',
  dropship_merging_warning:
    'Note: {countDropshipItems} item(s) cannot be merged and will be created as separate order'
};
export default en;
