import gql from 'graphql-tag';

export const repeatOrderItems = gql`
  mutation repeatOrderItems($orderId: Int!, $customerId: Int!) {
    repeatOrderItems(orderId: $orderId, customerId: $customerId) {
      unAvailableSkuIds
      success
    }
  }
`;

export const getOrderStatuses = gql`
  query getOrderStatuses {
    getOrderStatuses {
      id
      name
    }
  }
`;

export const getAvailableDeliverySlots = gql`
  query getAvailableDeliverySlots(
    $userActionDatetime: String!
    $tenantId: Int!
    $customerId: Int
    $items: [CheckDeliveryDateInput!]!
    $daysLimit: Int
    $isNewOrder: Boolean!
    $isAllowPassedCutOffTime: Boolean
  ) {
    getAvailableDeliverySlots(
      userActionDatetime: $userActionDatetime
      tenantId: $tenantId
      customerId: $customerId
      items: $items
      daysLimit: $daysLimit
      isNewOrder: $isNewOrder
      isAllowPassedCutOffTime: $isAllowPassedCutOffTime
    ) {
      date
      isAvailable
      noDeliveryReason
      slots {
        start
        end
        label
        leadHours
        isAvailable
        noDeliveryReason
        passedCutoffTime
      }
    }
  }
`;

export const getSupplierContactInfo = gql`
  query getSupplierContactInfo($tenantId: Int!, $customerId: Int!) {
    getSupplierContactInfo(tenantId: $tenantId, customerId: $customerId) {
      customerServiceNumber
      preferredCommunicationChannel
    }
  }
`;

export const getDeliveryFeeConfig = gql`
  query getDeliveryFeeConfig($tenantId: Int!, $customerId: Int!) {
    getDeliveryFeeConfig(tenantId: $tenantId, customerId: $customerId) {
      delivery_fee
      delivery_fee_tax
      delivery_fee_tax_rate
      delivery_fee_is_tax_inclusive
      max_order_value
      order_value_is_tax_inclusive
    }
  }
`;
